import {
    CREATE_NEW_USER,
    ON_CREATE_NEW_USER_START,
    ON_CREATE_NEW_USER_SUCCESS,
    ON_CREATE_NEW_USER_FAILED
} from './constants';

export const createNewUser = (history, name, username, email, password, roleIds) => {
    return ({
        type: CREATE_NEW_USER,
        history,
        name,
        username,
        email,
        password,
        roleIds
    });
}

export const onCreatingUserFailed = () => {
    return ({
        type: ON_CREATE_NEW_USER_FAILED,
    });
};

export const onCreatingUserStart = () => {
    return ({
        type: ON_CREATE_NEW_USER_START,
    });
};

export const onCreatingUserSuccess = () => {
    return ({
        type: ON_CREATE_NEW_USER_SUCCESS,
    });
};