// import consants
import {
    ON_ADD_MESSAGE,
    CLEAR_ALL_MESSAGES,
    ON_REMOVE_MESSAGE
} from '../constants/toastMessagesConstants';


const initialState = {
    messages: [],

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ON_ADD_MESSAGE:
            return { ...state, messages: [...state.messages, action.msg] };

        case ON_REMOVE_MESSAGE:
            return { ...state, messages: state.messages.filter((msg) =>  JSON.stringify(msg) !== JSON.stringify(action.obj) ) };

        case CLEAR_ALL_MESSAGES:
            return { ...state, messages: [] };

        default:
            return state;
    }
};

export default reducer;