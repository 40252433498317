import { takeLatest, put, call } from 'redux-saga/effects';

import { DELETE_PROJECT_DATA, EDIT_PROJECT_DATA, POST_PROJECT_DATA } from './constants';
import {
    onProjectDeleteFinished,
    onProjectDeleteStart,
    onProjectDeleteFailed,
    onProjectEditFinished,
    onProjectEditStart,
    onProjectEditFailed,
    onProjectPostFailed,
    onProjectPostStart,
    onProjectPostFinished,
} from './actions';
import { onAddPostMessage } from '../../actions/toastMessagesActions';
import { onGetProjectEventsDataSuccess } from '../../actions/projectEventsActions';
import { getTokenString, forwardTo, isTokenValid } from '../../Helpers';
import { apiBaseURL } from '../../ProjectControls';

function* editCategoryFunc(action) {
    const requestURL = `${apiBaseURL}api/Project `,
        fetchBody = {
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getTokenString()
            },
            body: JSON.stringify({
                startTimes: action.startTime,
                endTimes: action.endTime,
                Name: action.projectName,
                num: action.projectNumber,
                ProjectType: action.projectType,
                partner: action.partner,
                ManagerId : action.programManagerId,
                status: action.projectStatus,
                labId: action.lab,
                id: action.id
            })
        };
    if (isTokenValid()) {
        try {
            yield put(onProjectEditStart());
            const response = yield fetch(requestURL, fetchBody);
            const jsonData = yield response.json();

            if (response.status === 200 && jsonData.statusCode === 'Success') {
                yield put(onProjectEditFinished());
                yield put(onGetProjectEventsDataSuccess(jsonData.data));
            } else {
                yield put(onProjectEditFailed());
            }

            const color = jsonData.statusCode === 'Success' ? 'greenBG' : 'redBG';
            yield put(onAddPostMessage({ message: jsonData.messeges, color: color }))
        
        } catch (e) {
            console.log('An error has occured.', e);
            //yield put(onAddPostMessage({ message: 'An error has occured. Please tty again later.', color: 'redBG' }))
            yield put(onProjectEditFailed());        
        }
    } else {
        yield call(forwardTo, action.history, '/' );
    }
}

function* deleteCategoryFunc(action) {
    const requestURL = `${apiBaseURL}api/Project/${action.id}`,
        fetchBody = {
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getTokenString()
            },
        };
    if (isTokenValid()) {
        try {
            yield put(onProjectDeleteStart());

            const response = yield fetch(requestURL, fetchBody);
            const jsonData = yield response.json();

            if (response.status === 200 && jsonData.statusCode === 'Success') {
                yield put(onProjectDeleteFinished());
                yield put(onGetProjectEventsDataSuccess(jsonData.data));
            } else {
                yield put(onProjectDeleteFailed());
            }

            const color = jsonData.statusCode === 'Success' ? 'greenBG' : 'redBG';
            yield put(onAddPostMessage({ message: jsonData.messeges, color: color }))
        } catch (e) {
            console.log('An error has occured.', e);
            yield put(onProjectDeleteFailed());
        }
    } else {
        yield call(forwardTo, action.history, '/');
    }
}

function* postCategoryFunc(action) {
    const requestURL = `${apiBaseURL}api/Project `,
        fetchBody = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getTokenString()
            },
            body: JSON.stringify({
                startTimes: action.startTime,
                endTimes: action.endTime,
                Name: action.projectName,
                num: action.projectNumber,
                ProjectType: action.projectType,
                partner: action.partner,
                ManagerId: action.programManagerId,
                status: action.projectStatus,
                labId: parseInt(action.lab)
            })
        };

    if (isTokenValid()) {
        try {
            yield put(onProjectPostStart());

            const response = yield fetch(requestURL, fetchBody);
            const jsonData = yield response.json();
            //yield put(onActionCategorySuccess({ 'message': jsonData.responseMessage, 'color': jsonData.messageStanding }));

            if (response.status === 200 && jsonData.statusCode === 'Success') {
                yield put(onProjectPostFinished());
                yield put(onGetProjectEventsDataSuccess(jsonData.data));
            } else {
                yield put(onProjectPostFailed());
            }

            const color = jsonData.statusCode === 'Success' ? 'greenBG' : 'redBG';
            yield put(onAddPostMessage({ message: jsonData.messeges, color: color }))

        } catch (e) {
            console.log('An error has occured.', e);
            yield put(onProjectPostFailed());
        }
    } else {
        yield call(forwardTo, action.history, '/');
    }
}

export function* LabUsageSaga() {
    yield takeLatest(EDIT_PROJECT_DATA, editCategoryFunc);
    yield takeLatest(DELETE_PROJECT_DATA, deleteCategoryFunc);
    yield takeLatest(POST_PROJECT_DATA, postCategoryFunc);
}

export default LabUsageSaga;
