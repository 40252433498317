import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom'

import Toast from '../../component/Toast';
import Input from '../../component/Input';
import LoadingSpinnerModal from '../../component/LoadingSpinnerModal';
import { onAddPostMessage, onRemovePostMessage } from '../../actions/toastMessagesActions';
import atlasLogoWText from '../../Assets/images/AtlasLogoWText.jpg';
import NIARLogo from '../../Assets/images/NIARLogo.png';

class LogInPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        }
    }


    componentDidMount() {
        this.props.loginMessages && this.props.loginMessages.map(msg => {
            this.props.onAddPostMessage(msg);

        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loginMessages !== this.props.loginMessages) {
            this.props.loginMessages && this.props.loginMessages.map(msg => {
                this.props.onAddPostMessage(msg);
            })
        }
    }


    onLoginInInputChange = async (e) => {
        const inputName = e.target.id;
        //const inputNameError = `${e.target.id}Error`;
        const value = e.target.value;

        await this.setState({ [inputName]: value });

        /*if (inputName !== '') {
            await this.setState({ [inputNameError]: false })
        }*/
    }

    getToasts = () => {
        return this.props.messages && this.props.messages.map((msg, i) => {
            return msg.message.map((message) => {
                return <Toast BodyText={message} color={msg.color} key={i} onCloseToastClick={this.onCloseToastClick} />
            })
        })
    }

    onCloseToastClick = (e) => {
        this.props.onRemovePostMessage({ message: [e.target.name], color: e.target.value })
    }

    showSpinner = () => {
        if (this.props.showSpinner) {
            return <LoadingSpinnerModal />;
        }
    }

    render() {
        return (
            <div className="text-center container">
                <div className="row g-0 mx-0 px-0 mt-5">
                    {this.showSpinner()}
                    <div className="mb-5">
                        <img alt="NIAR Logo" className="niarLogo" src={NIARLogo} width="375" />
                        <img alt="Atlas Logo" src={atlasLogoWText} width="175" />
                    </div>
                    <form onSubmit={(e) => this.props.onLoginClick(e, this.state.email, this.state.password)} >
                        <Input
                            isError={this.props.error}
                            id="email"
                            onChange={this.onLoginInInputChange}
                            value={this.state.email}
                            labelTitle="Email"
                            type="text"
                        />
                        <Input
                            isError={this.props.error}
                            id="password"
                            onChange={this.onLoginInInputChange}
                            value={this.state.password}
                            labelTitle="Password"
                            type="password"
                        />
                        <button type="submit" className="col-12 button yellowBG mb-3">Submit</button>
                        <div className="col-12">
                            <Link to="/forgot-password">Forgot Password?</Link>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        messages: state.ToastMessagesReducer.messages,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ onAddPostMessage, onRemovePostMessage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LogInPage); 