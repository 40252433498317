import {
    ON_CHANGE_PASSWORD,
    ON_CHANGE_PASSWORD_START,
    ON_CHANGE_PASSWORD_SUCCESS,
    ON_CHANGE_PASSWORD_FAILED
} from './constants';

export const changePassword = (history, email, newPassword, oldPassword) => {
    return ({
        type: ON_CHANGE_PASSWORD,
        history,
        email,
        newPassword,
        oldPassword
    });
}

export const onChangePasswordFailed = () => {
    return ({
        type: ON_CHANGE_PASSWORD_FAILED,
    });
};

export const onChangePasswordStart = () => {
    return ({
        type: ON_CHANGE_PASSWORD_START,
    });
};

export const onChangePasswordSuccess = () => {
    return ({
        type: ON_CHANGE_PASSWORD_SUCCESS,
    });
};