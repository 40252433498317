import moment from 'moment';

/*=============================
1.)-----------Tokens-----------
=============================*/

export function isTokenValid() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    if (new Date() > new Date(userToken.expiresAt)) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');

        return false
    }

    return true;
}

export function getTokenString() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    return `Bearer ${userToken.userToken}`;
}

export function forwardTo(history, location) {
    history.push(location);
}

export function getUsersName() {
    const tokenString = sessionStorage.getItem('user');
    const userToken = JSON.parse(tokenString);

    return userToken?.name;
}

export function isNewUser() {
    const tokenString = sessionStorage.getItem('user');
    const userToken = JSON.parse(tokenString);

    return userToken?.isNewUser;
}

export function getUsersRoles() {
    const tokenString = sessionStorage.getItem('user');
    const userToken = JSON.parse(tokenString);

    return userToken?.roles;
}

export function getUsersEmail() {
    const tokenString = sessionStorage.getItem('user');
    const userToken = JSON.parse(tokenString);

    return userToken?.Email;
}

export function checkRoles(role) {
    const userRoles = getUsersRoles();
    if (userRoles) {
        return userRoles.includes(role);
    }

    return false;
}

/*=============================
2.)----------Calendar----------
=============================*/

export function seperateProjectsBasedOnLabs(array) {
    let SECEArr = [],
        SECCArr = [],
        SLITArr = [];

    array.map((project) => {
        project.startTime.map((startTime, i) => {
            project.endTime.map((endTime, j) => {
                if (i === j) {
                    if (project.lab.labCode === 'SECC') {
                        SECCArr.push({
                            start: new Date(startTime),
                            end: new Date(endTime),
                            title: project.name,
                            color: '#0000ff',
                            textColor: '#FFF',
                            id: project.id
                        })
                    } else if (project.lab.labCode === 'SECE') {
                        SECEArr.push({
                            start: new Date(startTime),
                            end: new Date(endTime),
                            title: project.name,
                            color: '#FFC217',
                            textColor: '#000',
                            id: project.id
                        })
                    } else if (project.lab.labCode === 'SLIT') {
                        SLITArr.push({
                            start: new Date(startTime),
                            end: new Date(endTime), 
                            title: project.name,
                            color: '#33cc33',
                            textColor: '#FFF',
                            id: project.id
                        })
                    }
                }
            });
        });
    });

    return { SECEArr, SECCArr, SLITArr}
}

export function reoccur(givenDates, day) {
    const daysOfWeek = ["", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    let dayINeed = daysOfWeek.indexOf(day);
    let start = null, end = null;

    givenDates.map((date, i) => {
        let newDate = null;
        const today = moment(date).isoWeekday();

        // if we haven't yet passed the day of the week that I need:
        if (today < dayINeed) {
            // then just give me this week's instance of that day
            newDate = moment(date).isoWeekday(dayINeed);
        } else {
            // otherwise, give me *next week's* instance of that same day
            newDate = moment(date).add(1, 'weeks').isoWeekday(dayINeed);
        }

        if (i === 0) {
            start = newDate;
        } else {
            end = newDate;
        }

    });

    return { start: start.toDate(), end: end.toDate() };
}

export function formatDate (date) {
    const day = date.toString().slice(0, 3);
    const dateWOYear = date.toString().slice(4, 10);
    const year = date.toString().slice(11, 15);
    const hh = date.getHours();
    let m = date.getMinutes();
    let dd = "AM";
    let h = hh;

    if (h >= 12) {
        h = hh - 12;
        dd = "PM";
    }

    if (h === 0) {
        h = 12;
    }

    m = m < 10 ? "0" + m : m;
    h = h < 10 ? "0" + h : h;

    return `${day}, ${dateWOYear}, ${year} ${h}:${m} ${dd}`;
}

export function isAnOverlapEvent(eventStartDay, eventEndDay, array) {
    console.log('h: ', eventStartDay, eventEndDay, array)
    // Events
    for (let i = 0; i < array.length; i++) {
        const eventA = array[i];

        // start-time in between any of the events
        if (eventStartDay > eventA.start && eventStartDay < eventA.end) {
            return true;
        }
        //end-time in between any of the events
        if (eventEndDay > eventA.start && eventEndDay < eventA.end) {

            return true;
        }
        //any of the events in between/on the start-time and end-time
        if (eventStartDay <= eventA.start && eventEndDay >= eventA.end) {

            return true;
        }
    }
    return false;
}