import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { apiBaseURL } from '../ProjectControls';
import useAuth from './useAuth';

export default function useUser() {
    const tokenStr = localStorage.getItem('token');
    const userToken = JSON.parse(tokenStr);
    const token = userToken?.token,
        [state, setState] = useState({
            loading: false,
            user: null,
            isNewUser: false,
            role: null
        })

    // Get user data
    const { data, error, mutate } = useSWR(
        token ? `${apiBaseURL}/api/AuthManagement/GetCurrentUser` : null,
        url =>
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then(res => res.json())
            .then(data => {
                if (data.success) {
                    localStorage.setItem('user', JSON.stringify({
                        name: `${data.user.firstName} ${data.user.lastName}`,
                        email: data.user.email,
                        role: data.user.role === 'Admin' ? 1 : 2,
                        new: data.user.isNewUser
                    }));
                }
            }),
        { revalidateOnFocus: false }
    );

    useEffect(() => {
        if (token) {
            setState({
                ...state,
                loading: !data && !error,
                user: data?.user,
                isNewUser: data?.user.isNewUser,
                role: data?.user.role
            })
        } else {
            setState({
                ...state,
                loading: !data && !error,
                user: null,
                isNewUser: false,
                role: null
            })
        }
       
    }, [data])


    return { user: state.user, mutate, loading: state.loading, role: state.role, isNewUser: state.isNewUser, error };
}