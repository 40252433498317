// import consants
import {
    ON_CHANGE_PASSWORD_START,
    ON_CHANGE_PASSWORD_SUCCESS,
    ON_CHANGE_PASSWORD_FAILED
} from './constants';


const initialState = {
    changePasswordStatus: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ON_CHANGE_PASSWORD_START:
            return { ...state, changePasswordStatus: 'Started' };

        case ON_CHANGE_PASSWORD_SUCCESS:
            return { ...state, changePasswordStatus: 'Changed' };

        case ON_CHANGE_PASSWORD_FAILED:
            return { ...state, changePasswordStatus: 'Failed' };

        default:
            return state;
    }
};

export default reducer;