import { takeLatest, put, call } from 'redux-saga/effects';

import { getTokenString, forwardTo, isTokenValid } from '../../Helpers';
import { apiBaseURL } from '../../ProjectControls';
import { CREATE_NEW_USER } from './constants';
import {
    onCreatingUserFailed,
    onCreatingUserStart,
    onCreatingUserSuccess,
} from './actions';

import { onAddPostMessage } from '../../actions/toastMessagesActions';

function* createUserFunc(action) {
    const requestURL = `${apiBaseURL}api/users/AddUser`,
        fetchBody = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getTokenString()
            },
            body: JSON.stringify({
                UserName: action.username,
                Email: action.email,
                Name: action.name,
                Password: action.password,
                RoleIds: action.roleIds
            })
        };
    if (isTokenValid()) {
        try {
            yield put(onCreatingUserStart());

            const response = yield fetch(requestURL, fetchBody);
            const jsonData = yield response.json();
            //yield put(onActionCategorySuccess({ 'message': jsonData.responseMessage, 'color': jsonData.messageStanding }));

            if (response.status === 200 && jsonData.statusCode === 'Success') {
                yield put(onCreatingUserSuccess());

            } else {
                yield put(onCreatingUserFailed());
            }

            const color = jsonData.statusCode === 'Success' ? 'greenBG' : 'redBG';
            yield put(onAddPostMessage({ message: jsonData.messeges, color: color }))



        } catch (e) {
            console.log('An error has occured.', e);
            yield put(onCreatingUserFailed());
        }
    } else {
        yield call(forwardTo, action.history, '/');
    }
}

export function* CreateUserSaga() {
    yield takeLatest(CREATE_NEW_USER, createUserFunc);
}

export default CreateUserSaga;
