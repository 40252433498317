import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import useUser from './useUser';
import { apiBaseURL } from '../ProjectControls';

export default function useAuth() {
    const { mutate } = useUser();

    const login = async (email, password) => {
        let success = false;
        let errors = [];

        await fetch(`${apiBaseURL}/api/authmanagement/login`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Access-Control-Allow-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                'Access-Control-Allow-Credentials': true,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        })
            .then(res => res.json())
            .then(json => {
                if (json.success) {
                    success = true;

                    localStorage.setItem('token', JSON.stringify({
                        token: json.token,
                        refreshToken: json.refreshToken,
                        tokenExpiration: json.tokenExpiration
                    }));
                    localStorage.setItem('user', JSON.stringify({
                        role: json.user.role === 'Admin' ? 1 : 2,
                        new: json.user.isNewUser
                    }));
                } else {
                    if (Array.isArray(json.errors)) {
                        errors = json.errors.map((message) => ({ isMessageInGDStanding: false, message }));
                    } else {
                        const arr = json.errors && Object.keys(json.errors).map((key) => json.errors[key]);
                        const notifArr = json.errors && [].concat.apply([], arr);

                        errors = json.errors ? notifArr.map((message) => ({ isMessageInGDStanding: false, message }))
                            : [{ isMessageInGDStanding: false, message: 'An error has occured. Please try again later.' }];
                    }
                }
            });

        await mutate();

        return { errors, success };
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    };

    // Refresh token for persisting session
    /*const { data, error, isValidating } = useSWR(
        isLogin ? `${apiBaseURL}/api/AuthManagement/RefreshToken` : null,
        url =>
            fetch(url, {
                credentials: 'include',
            }).then(res => res.json()),
        {
            // Silently refresh token every expiry time
            refreshInterval: 1000 * 60 * 15,
            revalidateOnFocus: false
        }
    );

    useEffect(() => {
        if (data) {
            login(data.accessToken);
        }
        if (error) {
            logout();
        }
        setIsLoading(isValidating);
    }, [data, error, isValidating]);
    */
    
    const tokenStr = localStorage.getItem('token');
    const userToken = JSON.parse(tokenStr);

    const getToken = () => {
        if (userToken) {
            const expire = new Date(userToken.tokenExpiration);
            const currentDate = new Date();

            if (currentDate < expire) {
                return userToken.token
            }

            logout();
            return null;
        }

        return null;
    }

    return {
        token: getToken(),
        refreshToken: userToken?.refreshToken,
        tokenExpiration: userToken?.tokenExpiration,
        isLogin: userToken ? true : false,
        login,
        logout,
    };
}