import { takeLatest, put, call } from 'redux-saga/effects';

import { GET_PROGRAM_MANAGER } from '../constants/programManagersConstants';
import { getTokenString, forwardTo, isTokenValid } from '../Helpers';
import { apiBaseURL } from '../ProjectControls';

import {
    onProgramManagerStart,
    onProgramManagerFailed,
    onProgramManagerFinished
} from '../actions/programManagersActions';

function* getProgramManagersFunc(action) {
    const requestURL = `${apiBaseURL}api/users/UsersinRole?roleId=6f252f4d-4b8d-417d-afc3-46778a33a973`;
    if (isTokenValid()) {
        try {
            yield put(onProgramManagerStart());

            const apiBody = {
                method: 'GET',
                headers: {
                    'Authorization': getTokenString()
                }
            };
            const response = yield fetch(requestURL, apiBody);
            const jsonData = yield response.json();
            yield put(onProgramManagerFinished(jsonData.users));
        
        } catch (e) {
            yield put(onProgramManagerFailed());
        }
    } else {
        yield call(forwardTo, action.history, '/');
    }
}

export function* ProgramManagersSaga() {
    yield takeLatest(GET_PROGRAM_MANAGER, getProgramManagersFunc);
}

export default ProgramManagersSaga;