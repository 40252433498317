import {
    ON_FORGOT_PASSWORD,
    ON_FORGOT_PASSWORD_START,
    ON_FORGOT_PASSWORD_SUCCESS,
    ON_FORGOT_PASSWORD_FAILED,
    ON_VALIDATE_SECURITY_CODE,
    ON_VALIDATE_SECURITY_CODE_START,
    ON_VALIDATE_SECURITY_CODE_SUCCESS,
    ON_VALIDATE_SECURITY_CODE_FAILED,
    ON_CHANGE_PASSWORD,
    ON_CHANGE_PASSWORD_START,
    ON_CHANGE_PASSWORD_SUCCESS,
    ON_CHANGE_PASSWORD_FAILED
} from './constants';

export const requestSecurityCode = (email) => {
    return ({
        email,
        type: ON_FORGOT_PASSWORD
    });
}

export const onRequestSecurityCodeFailed = () => {
    return ({
        type: ON_FORGOT_PASSWORD_FAILED,
    });
};

export const onRequestSecurityCodeStart = () => {
    return ({
        type: ON_FORGOT_PASSWORD_START,
    });
};

export const onRequestSecurityCodeSuccess = () => {
    return ({
        type: ON_FORGOT_PASSWORD_SUCCESS,
    });
};

// Validating
export const validateSecurityCode = (email, code) => {
    return ({
        email,
        code,
        type: ON_VALIDATE_SECURITY_CODE
    });
}

export const onValidateSecurityCodeFailed = () => {
    return ({
        type: ON_VALIDATE_SECURITY_CODE_FAILED,
    });
};

export const onValidateSecurityCodeStart = () => {
    return ({
        type: ON_VALIDATE_SECURITY_CODE_START,
    });
};

export const onValidateSecurityCodeSuccess = () => {
    return ({
        type: ON_VALIDATE_SECURITY_CODE_SUCCESS,
    });
};

// Change Password

export const changePassword = (email, password) => {
    return ({
        email,
        password,
        type: ON_CHANGE_PASSWORD
    });
}

export const onChangePasswordFailed = () => {
    return ({
        type: ON_CHANGE_PASSWORD_FAILED,
    });
};

export const onChangePasswordStart = () => {
    return ({
        type: ON_CHANGE_PASSWORD_START,
    });
};

export const onChangePasswordSuccess = () => {
    return ({
        type: ON_CHANGE_PASSWORD_SUCCESS,
    });
};
