export const versionNumber = '1.5.1';

export const apiBaseURL = 'https://atlasschedbe.hq.wsuniar.org/';

// export const apiBaseURL = 'http://freeman:2021/';

// export const apiBaseURL = 'https://localhost:44369/';

// default is 00:00:00
export const calendarStartTime = '06:00:00';
// default is 24:00:00
export const calendarEndTime = '20:00:00';

// Under Alia Haidar's Niar Account
export const recaptchSiteKey = '6LdLsWscAAAAAP_2C9cW5GPCjfAVSTd56_HuziwB';