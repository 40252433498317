import {
	ON_PROGRAM_MANAGER_START,
	ON_PROGRAM_MANAGER_FAILED,
	ON_PROGRAM_MANAGER_FINISHED
} from '../constants/programManagersConstants';

const initialState = {
    programManagers: [],
    programManagersStatus: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ON_PROGRAM_MANAGER_START:
            return { ...state, programManagersStatus: 'Started' };

        case ON_PROGRAM_MANAGER_FINISHED:
            return { ...state, programManagersStatus: 'Finished', programManagers: action.data };

        case ON_PROGRAM_MANAGER_FAILED:
            return { ...state, programManagersStatus: 'Failed' };

        default:
            return state;
    }
};

export default reducer;