// import consants
import {
    ON_CREATE_NEW_USER_START,
    ON_CREATE_NEW_USER_SUCCESS,
    ON_CREATE_NEW_USER_FAILED

} from './constants';


const initialState = {
    createUserStatus: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ON_CREATE_NEW_USER_START:
            return { ...state, createUserStatus: 'Started' };

        case ON_CREATE_NEW_USER_SUCCESS:
            return { ...state, createUserStatus: 'Created' };

        case ON_CREATE_NEW_USER_FAILED:
            return { ...state, createUserStatus: 'Failed' };

        default:
            return state;
    }
};

export default reducer;