export const ON_FORGOT_PASSWORD = 'schedulerFE/ForgotPasswordPage/ON_FORGOT_PASSWORD';
export const ON_FORGOT_PASSWORD_START = 'schedulerFE/ForgotPasswordPage/ON_FORGOT_PASSWORD_START';
export const ON_FORGOT_PASSWORD_SUCCESS = 'schedulerFE/ForgotPasswordPage/ON_FORGOT_PASSWORD_SUCCESS';
export const ON_FORGOT_PASSWORD_FAILED = 'schedulerFE/ForgotPasswordPage/ON_FORGOT_PASSWORD_FAILED';
// Validating Security Code
export const ON_VALIDATE_SECURITY_CODE = 'schedulerFE/ForgotPasswordPage/ON_VALIDATE_SECURITY_CODE';
export const ON_VALIDATE_SECURITY_CODE_START = 'schedulerFE/ForgotPasswordPage/ON_VALIDATE_SECURITY_CODE_START';
export const ON_VALIDATE_SECURITY_CODE_SUCCESS = 'schedulerFE/ForgotPasswordPage/ON_VALIDATE_SECURITY_CODE_SUCCESS';
export const ON_VALIDATE_SECURITY_CODE_FAILED = 'schedulerFE/ForgotPasswordPage/ON_VALIDATE_SECURITY_CODE_FAILED';
// Change Password
export const ON_CHANGE_PASSWORD = 'schedulerFE/ForgotPasswordPage/ON_CHANGE_PASSWORD';
export const ON_CHANGE_PASSWORD_START = 'schedulerFE/ForgotPasswordPage/ON_CHANGE_PASSWORD_START';
export const ON_CHANGE_PASSWORD_SUCCESS = 'schedulerFE/ForgotPasswordPage/ON_CHANGE_PASSWORD_SUCCESS';
export const ON_CHANGE_PASSWORD_FAILED = 'schedulerFE/ForgotPasswordPage/ON_CHANGE_PASSWORD_FAILED';