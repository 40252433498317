import { takeLatest, put, call } from 'redux-saga/effects';

import { getTokenString, forwardTo, isTokenValid } from '../../Helpers';
import { apiBaseURL } from '../../ProjectControls';
import { ON_CHANGE_PASSWORD } from './constants';
import {
    onChangePasswordFailed,
    onChangePasswordStart,
    onChangePasswordSuccess,
} from './actions';

import { onAddPostMessage } from '../../actions/toastMessagesActions';

function* changePasswordFunc(action) {
    const requestURL = `${apiBaseURL}api/users/ChangePassword`,
        fetchBody = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getTokenString()
            },
            body: JSON.stringify({
                Email: action.email,
                NewPassword: action.newPassword,
                OldPassword: action.oldPassword,
            })
        };
    if (isTokenValid()) {
        try {
            yield put(onChangePasswordStart());

            const response = yield fetch(requestURL, fetchBody);
            const jsonData = yield response.json();
            //yield put(onActionCategorySuccess({ 'message': jsonData.responseMessage, 'color': jsonData.messageStanding }));

            if (response.status === 200 && jsonData.statusCode === 'Success') {
                yield put(onChangePasswordSuccess());

            } else {
                yield put(onChangePasswordFailed());
            }

            const color = jsonData.statusCode === 'Success' ? 'greenBG' : 'redBG';
            yield put(onAddPostMessage({ message: jsonData.messeges, color: color }))



        } catch (e) {
            console.log('An error has occured.', e);
            yield put(onChangePasswordFailed());
        }
    } else {
        yield call(forwardTo, action.history, '/');
    }
}

export function* ChangePasswordSaga() {
    yield takeLatest(ON_CHANGE_PASSWORD, changePasswordFunc);
}

export default ChangePasswordSaga;
