import { takeLatest, put, call } from 'redux-saga/effects';

import { FETCH_PROJECT_DATA } from '../constants/projectEventsConstants';
import { getTokenString, forwardTo, isTokenValid } from '../Helpers';
import { apiBaseURL } from '../ProjectControls';

import {
    onGetProjectEventsDataStart,
    onGetProjectEventsDataFailed,
    onGetProjectEventsDataSuccess
} from '../actions/projectEventsActions';

function* getProjectFunc(action) {
    const requestURL = `${apiBaseURL}api/Project`;
    if (isTokenValid()) {
        try {
            yield put(onGetProjectEventsDataStart());

            const apiBody = {
                method: 'GET',
                headers: {
                    'Authorization': getTokenString()
                }
            };
            const response = yield fetch(requestURL, apiBody);
            const jsonData = yield response.json();

            yield put(onGetProjectEventsDataSuccess(jsonData));
        
        } catch (e) {
            yield put(onGetProjectEventsDataFailed());
        }
    } else {
        yield call(forwardTo, action.history, '/');
    }
}

export function* ProjectEventsSaga() {
    yield takeLatest(FETCH_PROJECT_DATA, getProjectFunc);
}

export default ProjectEventsSaga;
