import {
    ON_ADD_MESSAGE,
    CLEAR_ALL_MESSAGES,
    ON_REMOVE_MESSAGE
} from '../constants/toastMessagesConstants';

export const onRemovePostMessage = (obj) => {
    return ({
        type: ON_REMOVE_MESSAGE,
        obj
    });
}

export const onClearAllMessages = () => {
    return ({
        type: CLEAR_ALL_MESSAGES,
    });
}

export const onAddPostMessage = (msg) => {
    return ({
        type: ON_ADD_MESSAGE,
        msg
    });
}