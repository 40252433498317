import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams, } from 'react-router-dom';

//import Select from 'react-select'

import MyColorPicker from '../../component/ColorPicker';
import LoadingSpinnerModal from '../../component/LoadingSpinnerModal';
import Input from '../../component/Input';
import Select from '../../component/Select';
import ActionNotifications from '../../component/NewActionNotifications';
import './styles.css';
import useFetch from '../../Hooks/useFetch';
import ErrorMessage from '../../component/ErrorMessage';
import { apiBaseURL } from '../../ProjectControls';

export default function CreateLabPage({ props }) {
    const [state, setState] = useState({
        labName: '',
        labCode: '',
        labNameError: false,
        labCodeError: false,
        typeOfMeasurement: '',
        labIcon: '',
        labIconError: false,
        typeOfMeasurementError: false,
        typeOfMeasurementOptions: [{ value: 'Area', label: 'Area' }, { value: 'Length', label: 'Length' }],
        showSpinner: false,
        color: '#000000',
        textColor: '#000000',
        getLabLoading: false,
        colorError: false,
        textColorError: false,
        labId: null,
        notifications: []
    }),
        params = useParams(),
        { data, error } = useSWR(props.edit ? `${apiBaseURL}api/GetLab?LabName=${params.labName}` : null),
    fetch = useFetch(props.history);

    const onInputChange = async (e) => {
        const { name, value } = e.target;
        const inputNameError = `${name}Error`;
        let stateValues = { ...state };
        console.log(e.target, e.target.name , value)
        stateValues = { ...stateValues, [name]: value };

        if (name !== '') {
            stateValues = { ...stateValues, [inputNameError]: false };
        }   

        setState(stateValues);
    }

    const containsSpecialChars = (str) => {
        const specialChars = `&+?\\/#`;

        const result = specialChars.split('').some(specialChar => {
            if (str.includes(specialChar)) {
                return true;
            }

            return false;
        });

        return result;
    }

    const onCreateLabClick = async () => {
        let stateValues = { ...state };

        if (stateValues.labNameError || stateValues.labCodeError || stateValues.typeOfMeasurementError || stateValues.colorError || stateValues.textColorError || stateValues.labIconError) {
            stateValues.notifications.push({ isMessageInGDStanding: false, message: 'Please fix all error(s) before submitting.' })
        } 

        if (stateValues.labName === '') {
            stateValues = { ...stateValues, labNameError: true };
            stateValues.notifications.push({ isMessageInGDStanding: false, message: 'Lab name is required.' })
        }

        if (containsSpecialChars(stateValues.labName)) {
            stateValues = { ...stateValues, labNameError: true };
            stateValues.notifications.push({ isMessageInGDStanding: false, message: `Please remove special characters in Lab Name. This may include one or more of the following characters: &+?\\/#` })
        }

        if (stateValues.labCode === '') {
            stateValues = { ...stateValues, labCodeError: true };
            stateValues.notifications.push({ isMessageInGDStanding: false, message: 'Lab code is required.' })
        }

        if (stateValues.labCode.length !== 4) {
            stateValues = { ...stateValues, labCodeError: true };
            stateValues.notifications.push({ isMessageInGDStanding: false, message: 'Lab code must be 4 characters long.' })
        }

        if (stateValues.typeOfMeasurement === '') {
            stateValues = { ...stateValues, typeOfMeasurementError: true };
            stateValues.notifications.push({ isMessageInGDStanding: false, message: 'Lab measurement is required.' })
        }

        if (stateValues.color === '') {
            stateValues = { ...stateValues, colorError: true };
            stateValues.notifications.push({ isMessageInGDStanding: false, message: 'Lab color is required.' })
        }

        if (stateValues.color.slice(0, 1) !== '#' || (stateValues.color.length !== 4 && stateValues.color.length !== 7)) {
            stateValues = { ...stateValues, colorError: true };
            stateValues.notifications.push({ isMessageInGDStanding: false, message: 'Lab color is not a valid color. Must be 3 or 6 hexcode starting with "#".' })
        }

        if (stateValues.textColor === '') {
            stateValues = { ...stateValues, textColorError: true };
            stateValues.notifications.push({ isMessageInGDStanding: false, message: 'Text color is required.' })
        }

        if (stateValues.textColor.slice(0, 1) !== '#' || (stateValues.textColor.length !== 4 && stateValues.textColor.length !== 7)) {
            stateValues = { ...stateValues, textColorError: true };
            stateValues.notifications.push({ isMessageInGDStanding: false, message: 'Text color is not a valid color. Must be 3 or 6 hexcode starting with "#".' })
        }

        if (stateValues.color === stateValues.textColor) {
            stateValues = { ...stateValues, textColorError: true };
            stateValues.notifications.push({ isMessageInGDStanding: false, message: 'Text color cannot be the same as lab color.' })
        }

        if (stateValues.labIcon === '') {
            stateValues = { ...stateValues, labIconError: true };
            stateValues.notifications.push({ isMessageInGDStanding: false, message: 'Lab icon is required.' })
        }
        
        setState(stateValues);

        if (!stateValues.labNameError && !stateValues.labCodeError && !stateValues.typeOfMeasurementError && !stateValues.colorError && !stateValues.textColorError && !stateValues.labIconError) {
            let body = {
                labName: state.labName,
                MeasurementType: state.typeOfMeasurement,
                color: state.color,
                icon: state.labIcon,
                textColor: state.textColor,
                labCode: state.labCode,
            };

            let response = null;

            setState({ ...state, showSpinner: true });

            if (!props.edit) {
                response = await fetch.post(`${apiBaseURL}api/NewLab`, body);
            } else {
                body.id = state.labId;
                response = await fetch.put(`${apiBaseURL}api/EditLab`, body);
            }    

            if (response.success) {
                if (response.data.status === "Success") {
                    props.history.push('/labs')
                } else {
                    setState({
                        ...state,
                        showSpinner: false,
                        notifications: [].concat(state.notifications, response.data.toasts)
                    });
                }
            } else {
                let notifications = null;

                if (Array.isArray(response.errors)) {
                    notifications = response.errors ? response.errors : [{ isMessageInGDStanding: false, message: 'An error has occured. Please try again later.' }];

                } else {
                    notifications = [{ isMessageInGDStanding: false, message: 'An error has occurred. Please try again, and if the problem persists, contact the ATLAS software team.' }]
                }

                setState({
                    ...state,
                    showSpinner: false,
                    notifications: [].concat(state.notifications, notifications)
                });
            }
        }
    }

    /*useEffect(() => {
        if (state.notifications && state.notifications.length > 0) {
            let stateValues = { ...state };

            const clearNotifications = setTimeout(() => {
                stateValues = { ...state, notifications: [] };
            }, 5050)

            return () => {
                clearTimeout(clearNotifications)
                setState(stateValues);
            }
        }
    });*/

    useEffect(() => {
        if (data) {
            setState({
                ...state,
                color: data.color,
                typeOfMeasurement: data.measurementType,
                labName: data.labName,
                labIcon: data.icon,
                textColor: data.textColor,
                labCode: data.labCode,
                labId: data.id
            });
        }
    }, [data]);

    console.log(state.typeOfMeasurement)

    if (!data && !error && props.edit) {
        return <LoadingSpinnerModal show />;
    } else {
        return (
            <div className="px-5 content">
                <ActionNotifications arrayOfToasts={state.notifications} />
                <div className="px-5">
                    <div className="row mt-5 mb-3">
                        <h1 className="black">{props.edit ? `Edit ${params.labName}` : 'Add New Lab'}</h1>
                        <h5 className="gray">{props.edit ? 'Edit the information below to make changes to the lab.' : 'Fill out the information below to create a new lab.'}</h5>
                        <Link className="hoverLinkText gray" to="/labs" end>
                            <h5 className="mb-0">&#x2190; back</h5>
                        </Link>
                    </div>
                    <div className="boxCotainerWrapper offWhiteBG mt-3">
                        {!error ?
                            <>
                                <Input
                                    isError={state.labNameError}
                                    name="labName"
                                    onChange={onInputChange}
                                    value={state.labName}
                                    labelTitle="Lab Name"
                                    type="text"
                                    wrapperClassName="mb-3"
                                />
                                <Input
                                    isError={state.labCodeError}
                                    name="labCode"
                                    onChange={onInputChange}
                                    value={state.labCode}
                                    labelTitle="Lab Code"
                                    type="text"
                                    wrapperClassName="mb-3"
                                />
                                <Select
                                    isError={state.typeOfMeasurementError}
                                    arrayOfOptions={state.typeOfMeasurementOptions}
                                    name="typeOfMeasurement"
                                    onChange={onInputChange}
                                    labelTitle="Lab Measurement Type"
                                    selected={state.typeOfMeasurement}
                                    wrapperClassName="mb-3"
                                    placeholderOption
                                />
                                <div className="row mb-3 g-0">
                                    <MyColorPicker
                                        name="color"
                                        color={state.color}
                                        onChange={(value) => setState({ ...state, color: value.hex, colorError: false }) }
                                        onInputChange={onInputChange}
                                        colorError={state.colorError}
                                        wrapperClassName="mb-3"
                                        placeholder="Lab Color"
                                    />
                                </div>
                                <div className="row mb-3 g-0">
                                    <MyColorPicker
                                        name="textColor"
                                        color={state.textColor}
                                        onChange={(value) => setState({ ...state, textColor: value.hex, textColorError: false }) }
                                        onInputChange={onInputChange}
                                        colorError={state.textColorError}
                                        wrapperClassName="mb-3"
                                        placeholder="Text Color"
                                    />
                                </div>
                                <div className="mb-3">
                                    <Input
                                        isError={state.labIconError}
                                        name="labIcon"
                                        onChange={onInputChange}
                                        value={state.labIcon}
                                        labelTitle="Lab Icon"
                                        type="text"
                                        preInputText="fas-"
                                    />
                                    <div className="ps-2">
                                    Use this
                                        <a className="hoverLinkText yellow mx-2" href="https://fontawesome.com/search?m=free&s=brands%2Csolid" target="_blank" rel="noopener noreferrer">font-awesome link</a>
                                        to get a icon. Note: use free and solid icons ONLY.
                                    </div>
                                </div>
                                <button type="submit" className="col-12 button yellowBG white mb-3" disabled={state.showSpinner} onClick={onCreateLabClick}>
                                    {state.showSpinner ? <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="lg" /> : props.edit ? `Edit ${params.labName}` : 'Create Lab'}
                                </button>
                            </>
                        :
                            <ErrorMessage />
                        }
                    </div>
                </div>
            </div>
        );
    }
}
