// Example of a constant
/* export const CONSTANT_NAME = 'react-boilerplate/NewPage/CONSTANT_NAME'; */
export const DELETE_PROJECT_DATA = 'schedulerFE/LabUsagePage/DELETE_PROJECT_DATA';
export const ON_DELETE_PROJECT_DATA_START = 'schedulerFE/LabUsagePage/ON_DELETE_PROJECT_DATA_START';
export const ON_DELETE_PROJECT_DATA_FINISHED = 'schedulerFE/LabUsagePage/ON_DELETE_PROJECT_DATA_FINISHED';
export const ON_DELETE_PROJECT_DATA_FAILED = 'schedulerFE/LabUsagePage/ON_DELETE_PROJECT_DATA_FAILED';

export const EDIT_PROJECT_DATA = 'schedulerFE/LabUsagePage/EDIT_PROJECT_DATA';
export const ON_EDIT_PROJECT_DATA_START = 'schedulerFE/LabUsagePage/v';
export const ON_EDIT_PROJECT_DATA_FINISHED = 'schedulerFE/LabUsagePage/ON_EDIT_PROJECT_DATA_FINISHED';
export const ON_EDIT_PROJECT_DATA_FAILED = 'schedulerFE/LabUsagePage/ON_EDIT_PROJECT_DATA_FAILED';

export const POST_PROJECT_DATA = 'schedulerFE/LabUsagePage/POST_PROJECT_DATA';
export const ON_POST_PROJECT_DATA_START = 'schedulerFE/LabUsagePage/ON_POST_PROJECT_DATA_START';
export const ON_POST_PROJECT_DATA_FINISHED = 'schedulerFE/LabUsagePage/ON_POST_PROJECT_DATA_FINISHED';
export const ON_POST_PROJECT_DATA_FAILED = 'schedulerFE/LabUsagePage/ON_POST_PROJECT_DATA_FAILED';