import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import Select from 'react-select'

import Input from '../../component/Input';
import Select from '../../component/Select';
import { createNewUser } from './actions';
import { onAddPostMessage, onRemovePostMessage } from '../../actions/toastMessagesActions';
import ActionNotifications from '../../component/ActionNotifications';

class CreateAccountPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            name: '',
            email: '',
            userRole: [],
            userRoleSelectOptions: [
                { label: 'Program Manager', value: ["6f252f4d-4b8d-417d-afc3-46778a33a973", '24d8bcc8-6659-49bb-a357-50a9b44231cb', '7205394d-5d93-4eef-8a84-d2f1e9f5e9de', '856bc390-9827-48b1-bc4b-80242e57914e']},
                { label: 'Lab Manager', value: ['24d8bcc8-6659-49bb-a357-50a9b44231cb', '7205394d-5d93-4eef-8a84-d2f1e9f5e9de', '856bc390-9827-48b1-bc4b-80242e57914e'] },
                { label: 'Supervisor', value: ['7205394d-5d93-4eef-8a84-d2f1e9f5e9de', '856bc390-9827-48b1-bc4b-80242e57914e'] },
                { label: 'Staff', value: ['856bc390-9827-48b1-bc4b-80242e57914e']},
                { label: 'iPad Viewer', value: ['856bc390-9827-48b1-bc4b-80242e57914e', 'aab77ccb-983b-4541-bc93-c4762cb72007']}
            ],
            emailError: false,
            passwordError: false,
            nameError: false,
            userRoleError: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.createUserStatus !== this.props.createUserStatus) {
            if (this.props.createUserStatus === 'Created') {
                this.props.history.push('/');
            }
        }
    }

    onInputChange = async (e) => {
        const inputName = e.target.id;
        const inputNameError = `${e.target.id}Error`;
        const value = e.target.value;
        await this.setState({ [inputName]: value });

        if (inputName === 'userRole') {
            const arrayStr = this.state.userRole;
            const splitArray = arrayStr.search(',')

            if (splitArray > -1) {
                await this.setState({ userRole: arrayStr.split(',') });
            } else {
                await this.setState({ userRole: [arrayStr] });
            }

        }

        if (inputName !== '') {
            await this.setState({ [inputNameError]: false })
        }   
    }

    onCreateAccClick = async(e) => {
        if (this.state.email === '') {
            await this.setState({ emailError: true })
            this.props.onAddPostMessage({ message: ['Email is required.'], color: 'redBG' })
        }

        if (this.state.name === '') {
            await this.setState({ nameError: true })
            this.props.onAddPostMessage({ message: ['Name is required.'], color: 'redBG' })
        }

        if (this.state.password === '') {
            await this.setState({ passwordError: true })
            this.props.onAddPostMessage({ message: ['Password is required.'], color: 'redBG' })
        }

        if (this.state.userRole.length === 0) {
            await this.setState({ userRoleError: true })
            this.props.onAddPostMessage({ message: ['User Role is required.'], color: 'redBG' })
        }

        if (!this.state.emailError && !this.state.nameError && !this.state.passwordError && this.state.userRole.length > 0) { 
            this.props.createNewUser(this.props.history, this.state.name, this.state.email, this.state.email, this.state.password, this.state.userRole)
        }
    }

    render() {
        return (
            <div className="px-5 content">
                <ActionNotifications msgArray={this.props.messages} removeMessageFunc={this.props.onRemovePostMessage} />
                <div className="px-5">
                    <div className="row my-5">
                        <h1 className="black">Create An Account</h1>
                        <h5 className="gray">Fill out the form below to create a user.</h5>
                    </div>
                    <div className="boxCotainerWrapper offWhiteBG mx-0 mt-5">
                        <Input
                            isError={this.state.nameError}
                            id="name"
                            onChange={this.onInputChange}
                            value={this.state.name}
                            labelTitle="Name"
                            type="text"
                        />
                        <Input
                            isError={this.state.emailError}
                            id="email"
                            onChange={this.onInputChange}
                            value={this.state.email}
                            labelTitle="Email"
                            type="text"
                        />
                        <Input
                            isError={this.state.passwordError}
                            id="password"
                            onChange={this.onInputChange}
                            value={this.state.password}
                            labelTitle="Temporary Password"
                            type="password"
                        />
                        <Select
                            isError={this.state.userRoleError}
                            arrayOfOptions={this.state.userRoleSelectOptions}
                            id="userRole"
                            onChange={this.onInputChange}
                            labelTitle="User Role"
                            placeholderOption
                        />
                        {/*<Select
                            id="userRole"
                            options={this.state.userRoleSelectOptions}
                            onChange={this.onUserRoleChange}
                            styles="select ltGrayBG mb-3"
                            isMulti
                        />*/}
                        <button type="submit" className="col-12 button yellowBG white mb-3" onClick={(e) => this.onCreateAccClick(e)}>Create Account</button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        createUserStatus: state.CreateAccountReducer.createUserStatus,
        messages: state.ToastMessagesReducer.messages,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ createNewUser, onAddPostMessage, onRemovePostMessage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountPage);