import { combineReducers } from 'redux';
// import files here
import ProjectEventsReducer from './projectEventsReducer';
import LabsReducer from './labsReducer';
import programManagersReducer from './programManagersReducer';
import CalendarPageReducer from '../container/CalendarPage/reducer';
import CreateAccountReducer from '../container/CreateAccountPage/reducer';
import ToastMessagesReducer from './toastMessagesReducer';
import ChangePasswordReducer from '../container/FirstTimeUserPage/reducer';
import forgotPasswordReducer from '../container/ForgotPasswordPage/reducer';

const reducer = () => {
  // inside the combineReducers put name created in the reducer.js of that folder
  // ":" the name of the file imported in this file.
    return combineReducers({
        ProjectEventsReducer,
        LabsReducer,
        CalendarPageReducer,
        ToastMessagesReducer,
        CreateAccountReducer,
        ChangePasswordReducer,
        programManagersReducer,
        forgotPasswordReducer
    });
};

export default reducer;