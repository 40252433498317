// import constants below
import {
    DELETE_PROJECT_DATA,
    ON_DELETE_PROJECT_DATA_START,
    ON_DELETE_PROJECT_DATA_FINISHED,
    ON_DELETE_PROJECT_DATA_FAILED,
    EDIT_PROJECT_DATA,
    ON_EDIT_PROJECT_DATA_START,
    ON_EDIT_PROJECT_DATA_FINISHED,
    ON_EDIT_PROJECT_DATA_FAILED,
    POST_PROJECT_DATA,
    ON_POST_PROJECT_DATA_START,
    ON_POST_PROJECT_DATA_FINISHED,
    ON_POST_PROJECT_DATA_FAILED,

} from './constants';

export const editLabProject = (history, startTime, endTime, projectName, projectNumber, projectType, partner, programManagerId, projectStatus, lab, id) => {
    return ({
        type: EDIT_PROJECT_DATA,
        history,
        startTime,
        endTime,
        projectName,
        projectNumber,
        projectType,
        partner,
        programManagerId,
        projectStatus,
        lab,
        id
    });
}

export const onProjectEditFailed = () => {
    return ({
        type: ON_EDIT_PROJECT_DATA_FAILED,
    });
};

export const onProjectEditStart = () => {
    return ({
        type: ON_EDIT_PROJECT_DATA_START,
    });
};

export const onProjectEditFinished = () => {
    return ({
        type: ON_EDIT_PROJECT_DATA_FINISHED,
    });
};

export const deleteLabProject = (history, id) => {
    return ({
        type: DELETE_PROJECT_DATA,
        history,
        id
    });
}

export const onProjectDeleteFailed = () => {
    return ({
        type: ON_DELETE_PROJECT_DATA_FAILED,
    });
};

export const onProjectDeleteStart = () => {
    return ({
        type: ON_DELETE_PROJECT_DATA_START,
    });
};

export const onProjectDeleteFinished = () => {
    return ({
        type: ON_DELETE_PROJECT_DATA_FINISHED,
    });
};

export const createLabProject = (history, startTime, endTime, projectName, projectNumber, projectType, partner, programManagerId, projectStatus, lab) => {
    return ({
        type: POST_PROJECT_DATA,
        history,
        startTime,
        endTime,
        projectName,
        projectNumber,
        projectType,
        partner,
        programManagerId,
        projectStatus,
        lab
    });
}

export const onProjectPostFailed = () => {
    return ({
        type: ON_POST_PROJECT_DATA_FAILED,
    });
};

export const onProjectPostStart = () => {
    return ({
        type: ON_POST_PROJECT_DATA_START,
    });
};

export const onProjectPostFinished = () => {
    return ({
        type: ON_POST_PROJECT_DATA_FINISHED,
    });
};