import {
    ON_FETCH_LAB_DATA_START,
    ON_FETCH_LAB_DATA_SUCCESS,
    ON_FETCH_LAB_DATA_FAILED
} from '../constants/labsConstants';

const initialState = {
    labsData: [],
    labsDataStatus: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ON_FETCH_LAB_DATA_START:
            return { ...state, labsDataStatus: 'Started' };

        case ON_FETCH_LAB_DATA_SUCCESS:
            return { ...state, labsDataStatus: 'Finished', labsData: action.data };

        case ON_FETCH_LAB_DATA_FAILED:
            return { ...state, labsDataStatus: 'Failed' };

        default:
            return state;
    }
};

export default reducer;