import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './styles.css';



class NotFoundPage extends React.Component {
    constructor() {
        super();
        this.state = {
        };
       
    }

    render() {
        return (
            <div id="content" className="m-5 ">
                <div className="boxCotainerWrapper text-center">
                    <FontAwesomeIcon icon={faQuestion} className="fa-spin mt-5 mb-4 yellow" size="8x" />
                    <div className="header404Txt"><b>404</b></div>
                    <h2>UH OH! You`re Lost!</h2>
                    <div className="pt-3 pb-5">
                        <h5 className="px-5">The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.</h5>
                    </div>
                    <Link className="linkHomeBtn black primaryVariantBG" to="/"><b>Home</b></Link>
                </div>
            </div>
        );
    }
}

export default NotFoundPage;