import React, { useState } from 'react';
import { Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';

//import files here
import CalendarPage from './container/CalendarPage/index';
import LogInPage from './container/LogInPage/index';
import CreateAccountPage from './container/CreateAccountPage/index';
import CreateLabPage from './container/CreateLabPage/index';
import LabsPage from './container/LabsPage/index';
import FirstTimeUserPage from './container/FirstTimeUserPage/index';
import ProfilePage from './container/ProfilePage/index';
import NotFoundPage from './container/NotFoundPage/index';
import ForgotPasswordPage from './container/ForgotPasswordPage/index';

import Navbar from './component/Navbar/index';
import { getUsersName, isNewUser, checkRoles, getUsersRoles } from './Helpers'
import { apiBaseURL } from './ProjectControls';

function PrivateRoute({ children, authRole, ...rest }) {
    console.log(0)
    const token = getToken();
    return (
        <Route
            {...rest}
            render={() => {
                if (token && checkRoles(authRole) && !isNewUser()) {
                    console.log(1)

                    return children
                } else if (token && isNewUser()) {
                    console.log(2)

                    return <Redirect to="/new-login" />;
                } else if (token && !isNewUser()) {
                    console.log(3)

                    return <Redirect to="/" />;
                } else {
                    console.log(4)

                    return <Redirect to="/login" />;
                }
            }}
        />
    );
}

function NewUserPrivateRoute({ children, ...rest }) {
    const token = getToken();

    return (
        <Route
            {...rest}
            render={() => {
                if (token && isNewUser()) {
                    return children
                } else if (token && !isNewUser()) {
                    return (
                        <div>
                            <AuthNavbar currentPage="quote" />
                            <NotFoundPage />
                        </div>
                    );
                } else {
                    return <Redirect to="/login" />;
                }
            }}
        />
    );
}

async function postUser(credentials) {
    return fetch(`${apiBaseURL}api/Account/Login`, {
        method: 'POST',
        credentials: 'include',
        //credentials: 'same-origin',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials)
    }).then(data => {
        return data.json()
    }).catch(error => {
        return error
    })
}


function AuthNavbar () {
    const history = useHistory();

    const logOut = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        history.push('/login')
    }

    return <Navbar showProject={checkRoles("Lab-Manager")} showUser={checkRoles("Program-Manager")} name={getUsersName()} onSignOutClick={logOut} />;
}

function Scheduler() {
    const history = useHistory();

    return <CalendarPage canAddProject={checkRoles("Lab-Manager")} canShowProject={checkRoles("Supervisor")} history={history} />;
}

function setToken(userToken, expiresAt) {
    sessionStorage.setItem('token', JSON.stringify({ userToken, expiresAt}));
}

function setUser(name, roles, isNewUser, Email) {
    sessionStorage.setItem('user', JSON.stringify({ name, roles, isNewUser, Email }));
}


function getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    if (userToken && new Date() > new Date(userToken.expiresAt)) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');

        return null;
    }

    return userToken?.userToken;
}

function Login() {
    const [
        redirectToReferrer,
        setRedirectToReferrer,
    ] = useState({ redirect: false, newUser: false });

    const [
        isError,
        setIsError
    ] = useState(false);

    const [
        isLoading,
        setIsLoading
    ] = useState(false)

    let messages = [];

    const login = async (e, Email, Password) => {
        e.preventDefault();

        setIsLoading(true)
        const tokenAPI = await postUser({
            Email,
            Password,
        });

        if (tokenAPI.result && tokenAPI.result.statusCode === 'Success') {
            setIsLoading(false)
            setToken(tokenAPI.result.messeges[1], tokenAPI.result.messeges[0])
            setUser(tokenAPI.firstName, tokenAPI.roles, tokenAPI.isNewUser, Email)
            if (getToken()) {
                setRedirectToReferrer(true);
                setRedirectToReferrer(prevState => ({
                    ...prevState,
                    redirect: true,
                    newUser: tokenAPI.isNewUser
                }));
            }

        } else {
            setIsLoading(false)
            setIsError(true);
        }
    }

    if (redirectToReferrer.redirect) {
        if (!redirectToReferrer.newUser) {
            return <Redirect to='/' />
        }
        return <Redirect to='/new-login' />
    }

    return <LogInPage onLoginClick={login} error={isError} loginMessages={messages} showSpinner={isLoading} />
}

const Routes = (history) => {
    return (
        <Router onUpdate={() => { window.scrollTo(0, 0); }} history={history}>
            <Switch>
                <Route exact path="/login">
                    <Login />
                </Route>

                <Route exact path="/forgot-password">
                    <ForgotPasswordPage history={history} />
                </Route>

                <NewUserPrivateRoute exact path="/new-login">
                    <FirstTimeUserPage history={history} />
                </NewUserPrivateRoute>

                <PrivateRoute authRole="Program-Manager" exact path="/create-user">
                    <AuthNavbar />
                    <CreateAccountPage history={history} />
                </PrivateRoute>

                <PrivateRoute authRole="Program-Manager" exact path="/labs">
                    <AuthNavbar />
                    <LabsPage history={history} />
                </PrivateRoute>

                <PrivateRoute authRole="Program-Manager" exact path="/labs/new">
                    <AuthNavbar />
                    <CreateLabPage props={{ edit: false, history }} />
                </PrivateRoute>

                <PrivateRoute authRole="Program-Manager" exact path="/labs/:labName/edit">
                    <AuthNavbar />
                    <CreateLabPage props={{ edit: true, history }} />
                </PrivateRoute>

                <PrivateRoute exact authRole="Staff" path="/">
                    <AuthNavbar />
                    <Scheduler />
                </PrivateRoute>

                <PrivateRoute exact authRole="Staff" path="/profile">
                    <AuthNavbar />
                    <ProfilePage />
                </PrivateRoute>

                <PrivateRoute authRole="Staff">
                    <AuthNavbar />
                    <NotFoundPage />
                </PrivateRoute>
            </Switch>
        </Router>
    );
};

export default Routes;
