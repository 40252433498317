// import consants
import {
    ON_DELETE_PROJECT_DATA_START,
    ON_DELETE_PROJECT_DATA_FINISHED,
    ON_DELETE_PROJECT_DATA_FAILED,
    ON_EDIT_PROJECT_DATA_START,
    ON_EDIT_PROJECT_DATA_FINISHED,
    ON_EDIT_PROJECT_DATA_FAILED,
    ON_POST_PROJECT_DATA_START,
    ON_POST_PROJECT_DATA_FINISHED,
    ON_POST_PROJECT_DATA_FAILED,
} from './constants';


const initialState = {
    deleteLabProjectStatus: '',
    editLabProjectStatus: '',
    postLabProjectStatus: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //create
        case ON_POST_PROJECT_DATA_START:
            return { ...state, postLabProjectStatus: 'Started' };

        case ON_POST_PROJECT_DATA_FINISHED:
            return { ...state, postLabProjectStatus: 'Created' };

        case ON_POST_PROJECT_DATA_FAILED:
            return { ...state, postLabProjectStatus: 'Failed' };
        // Delete
        case ON_DELETE_PROJECT_DATA_START:
            return { ...state, deleteLabProjectStatus: 'Started' };

        case ON_DELETE_PROJECT_DATA_FINISHED:
            return { ...state, deleteLabProjectStatus: 'Deleted' };

        case ON_DELETE_PROJECT_DATA_FAILED:
            return { ...state, deleteLabProjectStatus: 'Failed' };

        //edit
        case ON_EDIT_PROJECT_DATA_START:
            return { ...state, editLabProjectStatus: 'Started' };

        case ON_EDIT_PROJECT_DATA_FINISHED:
            return { ...state, editLabProjectStatus: 'Edited' };

        case ON_EDIT_PROJECT_DATA_FAILED:
            return { ...state, editLabProjectStatus: 'Failed' };

        default:
            return state;
    }
};

export default reducer;