import useAuth from '../Hooks/useAuth';

export default function useFetch({ history }) {
    const { isLogin, token, logout } = useAuth();

    const getToken = () => {
        const tokenStr = localStorage.getItem('token');
        const userToken = JSON.parse(tokenStr);

        if (userToken) {
            const expire = new Date(userToken.tokenExpiration);
            const currentDate = new Date();

            if (currentDate < expire) {
                return userToken.token;
            }

            logout();
            history.push('/')
        }

        logout();
        history.push('/')
    }

    //swr will be taking care of get. 
    function get(url) {
        const requestOptions = {
            method: 'GET'
        };
        return fetch(url, requestOptions).then(handleResponse);
    }

    function post(url, body) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                ...authHeader()
            },
            credentials: 'include',
            body: JSON.stringify(body)
        };

        return fetch(url, requestOptions).then(handleResponse);
    }

    function postFile(url, file) {
        const requestOptions = {
            method: 'POST',
            headers: {
                ...authHeader()
            },
            credentials: 'include',
            body: file
        };

        return fetch(url, requestOptions).then(handleResponse);
    }

    function put(url, body) {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                ...authHeader()
            },
            body: JSON.stringify(body)
        };
        return fetch(url, requestOptions).then(handleResponse);
    }

    // prefixed with underscored because delete is a reserved word in javascript
    function _delete(url) {
        const requestOptions = {
            method: 'DELETE',
            headers: authHeader()
        };
        return fetch(url, requestOptions).then(handleResponse);
    }

    // helper functions

    function authHeader(auth) {
        // return auth header with jwt if user is logged in and request is to the api url
        if (isLogin) {
            return { Authorization: `Bearer ${token}` };
        } else {
            console.log('not logged in (usefetch)')

            logout();
        }
    }

    function handleResponse(response) {
        console.log('dsffdsdfsdf', response)
        return response.json().then(data => {
            if (!response.ok) {
                if ((response.status === 403 || response.status === 403) || !isLogin) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    logout();
                    history.push('/')
                }
                const errors = (data && data.errors) || response.statusText;

                return { success: false, errors, logout: false, data: null };
            }
            return { success: true, errors: null, logout: false, data };
        });
    }

    return {
        get,
        post,
        postFile,
        put,
        delete: _delete
    };
}