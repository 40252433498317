import { fork, all } from 'redux-saga/effects';
//import file here & file name should be the same as export default file name in that saga file
import LabsSaga from './labsSaga';
import ProjectEventsSaga from './projectEventsSaga';
import ProgramManagerSaga from './programManagerSaga';
import CalendarPageSaga from '../container/CalendarPage/saga';
import CreateUserSaga from '../container/CreateAccountPage/saga';
import ChangePasswordSaga from '../container/ForgotPasswordPage/saga';
import ForgotPasswordSaga from '../container/FirstTimeUserPage/saga';


// ex in yeild: fork(filename imported)
function* rootSaga() {
    yield all([
        fork(LabsSaga),
        fork(ProjectEventsSaga),
        fork(CalendarPageSaga),
        fork(CreateUserSaga),
        fork(ChangePasswordSaga),
        fork(ForgotPasswordSaga),
        fork(ProgramManagerSaga)
    ]);
}

export default rootSaga;
