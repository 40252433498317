// import consants
import {
    ON_FORGOT_PASSWORD_START,
    ON_FORGOT_PASSWORD_SUCCESS,
    ON_FORGOT_PASSWORD_FAILED,
    ON_VALIDATE_SECURITY_CODE_START,
    ON_VALIDATE_SECURITY_CODE_SUCCESS,
    ON_VALIDATE_SECURITY_CODE_FAILED,
    ON_CHANGE_PASSWORD_START,
    ON_CHANGE_PASSWORD_SUCCESS,
    ON_CHANGE_PASSWORD_FAILED
} from './constants';


const initialState = {
    requestSecurityCodeStatus: 'Idle',
    validateSecurityCodeStatus: 'Idle',
    changePasswordStatus: 'Idle'
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ON_FORGOT_PASSWORD_START:
            return { ...state, requestSecurityCodeStatus: 'Started' };

        case ON_FORGOT_PASSWORD_SUCCESS:
            return { ...state, requestSecurityCodeStatus: 'Sent' };

        case ON_FORGOT_PASSWORD_FAILED:
            return { ...state, requestSecurityCodeStatus: 'Failed' };

        // Validate
        case ON_VALIDATE_SECURITY_CODE_START:
            return { ...state, validateSecurityCodeStatus: 'Started' };

        case ON_VALIDATE_SECURITY_CODE_SUCCESS:
            return { ...state, validateSecurityCodeStatus: 'Validated' };

        case ON_VALIDATE_SECURITY_CODE_FAILED:
            return { ...state, validateSecurityCodeStatus: 'Failed' };

        // Change Password
        case ON_CHANGE_PASSWORD_START:
            return { ...state, changePasswordStatus: 'Started' };

        case ON_CHANGE_PASSWORD_SUCCESS:
            return { ...state, changePasswordStatus: 'Changed' };

        case ON_CHANGE_PASSWORD_FAILED:
            return { ...state, changePasswordStatus: 'Failed' };
        default:
            return state;
    }
};

export default reducer;