import { takeLatest, put, call } from 'redux-saga/effects';

import { FETCH_LAB_DATA } from '../constants/labsConstants';
import {
    onGetLabsDataStart,
    onGetLabsDataFailed,
    onGetLabsDataSuccess
} from '../actions/labsActions';
import { getTokenString, forwardTo, isTokenValid } from '../Helpers';
import { apiBaseURL } from '../ProjectControls';

function* getLabsFunc(action) {
    const requestURL = `${apiBaseURL}api/Labs`;
    if (isTokenValid()) {
        try {
            yield put(onGetLabsDataStart());

            const apiBody = {
                method: 'GET',
                headers: {
                    'Authorization': getTokenString()
                },
            }

            const response = yield fetch(requestURL, apiBody);
            const jsonData = yield response.json();

            yield put(onGetLabsDataSuccess(jsonData));
        
        } catch (e) {
            console.log('An error has occured.', e);
            yield put(onGetLabsDataFailed());
        }
    } else {
        yield call(forwardTo, action.history, '/');
    }
}

export function* LabsSaga() {
    yield takeLatest(FETCH_LAB_DATA, getLabsFunc);
}

export default LabsSaga;
