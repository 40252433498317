import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import Select from 'react-select'

import Input from '../../component/Input';
import Select from '../../component/Select';
import { createNewUser } from './actions';
import { onAddPostMessage, onRemovePostMessage } from '../../actions/toastMessagesActions';
import ActionNotifications from '../../component/ActionNotifications';
import { versionNumber } from '../../ProjectControls';
import './styles.css'
import { getUsersEmail, getUsersName } from '../../Helpers'


class ProfilePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }

    componentDidUpdate(prevProps) {
       
    }

    render() {
        return (
            <div className="m-5">
                <ActionNotifications msgArray={this.props.messages} removeMessageFunc={this.props.onRemovePostMessage} />
                <div className="row">
                    <div className="col-12">
                        <h2>Personal Information</h2>
                    </div>
                    <div className="col-12 m-2">
                        <p className="fs-3">{getUsersName()}</p>
                        <p className="fs-3">{getUsersEmail()}</p>
                    </div>
                    {/*<hr />
                    <div className="col-12">
                        <h2>Security</h2>
                    </div>*/}
                </div>
                <div className="footerVersionNum mb-2 me-3">Version {versionNumber}</div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        messages: state.ToastMessagesReducer.messages,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ onAddPostMessage, onRemovePostMessage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);