import React, { useState } from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
//import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

import LoadingSpinnerModal from '../../component/AtlasLoadingSpinnerModal';
import Modal from '../../component/Modal';

import './styles.css'
import AdminControls from '../../component/AdminActions';
import useFetch from '../../Hooks/useFetch';
import ActionNotifications from '../../component/NewActionNotifications';
import ErrorMessage from '../../component/ErrorMessage';
import { apiBaseURL } from '../../ProjectControls';

library.add(fas);

export default function CreateLabPage({ history }) {
    const { data, mutate, error } = useSWR(`${apiBaseURL}api/labs`, { refreshInterval: 10000 }),
        fetch = useFetch(history),
        [state, setState] = useState({
            showSpinnerModal: false,
            showDeleteLabModel: false,
            labToDelete: '',
            notifications: []
        });

    const onShowDeleteLabModal = () => {
        if (state.showDeleteLabModel) {
            return (
                <Modal
                    modalHeaderTitle={`Delete ${state.labToDelete}`}
                    modalBody={`Do you wish to delete ${state.labToDelete}`}
                    onModalCloseClick={() => setState({ ...state, showDeleteLabModel: false, labToDelete: '' })}
                    colorBtnClass="redBG white"
                    modalFooterBtnText="Delete"
                    onModalFooterBtnClick={onLabDelete}
                    onModalSecondaryFooterBtnClick={() => setState({ ...state, showDeleteLabModel: false, labToDelete: '' })}
                    modalSecondaryFooterBtnText="Cancel"
                    secondaryColorBtnClass="yellowBG white"
                />
            );
        }
    }

    const onLabDelete = async () => {
        setState({ ...state, showSpinnerModal: true, showDeleteLabModel: false });

        const response = await fetch.delete(`${apiBaseURL}api/DeleteLab?id=${state.labIdToDelete}`);

        if (response.success) {
            mutate();
            setState({
                ...state,
                showSpinnerModal: false,
                showDeleteLabModel: false,
                labToDelete: '',
                notifications: [].concat(state.notifications, response.data.toasts)

            });
        } else {
            let notifications = null;

            if (Array.isArray(response.errors)) {
                notifications = response.errors ? response.errors : [{ isMessageInGDStanding: false, message: 'An error has occured. Please try again later.' }];
            } else {
                notifications = [{ isMessageInGDStanding: false, message: 'An error has occurred. Please try again, and if the problem persists, contact the ATLAS software team.' }]
            }

            setState({
                ...state,
                showSpinnerModal: false,
                showDeleteLabModel: false,
                notifications: [].concat(state.notifications, notifications)
            });
        }
    }

    const columns = [
        {
            field: 'icon',
            headerName: 'Icon',
            flex: 0.5,
            renderCell: (params) => {
                const iconShape = params.row.icon ? params.row.icon : 'ban';

                return (<FontAwesomeIcon icon={[fas, iconShape ]} className="ms-2" size="lg" />);
            }
        },
        { field: 'labName', headerName: 'Lab Name', flex: 2 },
        { field: 'labCode', headerName: 'Lab Code', flex: 2 },
        { field: 'measurementType', headerName: 'Measurement Type', flex: 2 },
        {
            field: 'action',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => {
                return (
                    <AdminControls
                        editLink={`/labs/${params.row.labName}/edit`}
                        onDeleteClick={() => { setState({ ...state, labIdToDelete: params.row.id, labToDelete: params.row.labName, showDeleteLabModel: true }) }}
                    />
                );
            }
        }
    ];

    const renderGrid = () => {
        if (data.length > 0) {
            return (
                <>
                    {onShowDeleteLabModal()}
                    <DataGrid
                        rows={data}
                        columns={columns}
                        getRowId={(row) => row.id}
                        pageSize={10}
                        disableSelectionOnClick
                        autoHeight
                        autoWidth
                    />
                    <div className="col-12 text-start mt-3">
                        <h5 className="noBold d-inline-block mb-0">Don't see the lab you are looking for?
                            <Link to="labs/new" className="ms-2 hoverLinkText yellow d-inline-block pointer">Create Lab here.</Link>
                        </h5>
                    </div>
                </>
            );
        } else {
            return (
                <h5 className="mb-0 text-center">
                    No labs have been created. Create a lab
                    <Link to="labs/new" className="ms-2 hoverLinkText yellow d-inline-block pointer">here.</Link>
                </h5>
            );
        }
    }

    if (!data & !error) {
        return <LoadingSpinnerModal show />;
    } else {
        return (
           <div className="px-5 content">
                <ActionNotifications arrayOfToasts={state.notifications} />
                <LoadingSpinnerModal show={state.showSpinnerModal} />
                <div className="px-5">
                    <div className="row my-5">
                        <h1 className="black">All Labs</h1>
                        <h5 className="gray">Create, edit, and manage labs below.</h5>
                    </div>
                    <div className="boxCotainerWrapper offWhiteBG my-3">
                        {!error ?
                            renderGrid()
                        :
                            <ErrorMessage />
                        }
                    </div>
                </div>
           </div>
        );
    }
}