import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import './styles.css';
import Modal from '../../component/Modal';
import Select from '../../component/Select';
import MultiStepper from '../../component/MultiStepper';
import Input from '../../component/Input';
import SearchDropDown from '../../component/SearchDropDown';
import ActionNotifications from '../../component/ActionNotifications';

import { getLabs } from '../../actions/labsActions';
import { getProgramManager } from '../../actions/programManagersActions';
import { getProjectEventsData, addFilteredData, removeFilteredData } from '../../actions/projectEventsActions';
import { onAddPostMessage, onRemovePostMessage } from '../../actions/toastMessagesActions';
import { editLabProject, deleteLabProject, createLabProject } from './actions';
import { calendarStartTime, calendarEndTime } from '../../ProjectControls';


class CalendarPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            existingProjectsError: false,
            intialLoadBool: false,
            isNewProject: false,
            labSelectError: false,
            partnerInputError: false,
            partnerInputError: false,
            projectNameInputError: false,
            projectNumberInputError: false,
            projectTypeSelectError: false,
            programManagerSelectError: false,
            selectedStartTimeError: false,
            selectedEndTimeError: false,
            showCreateProjectModel: false,
            showProjectInfoModal: false,
            showProjectDeleteModal: false,

            existingProjects: '',
            firstLabCodeToRender: '',
            justAddedLabId: '',
            labSelect: '',
            originalSelectedEndTime: '',
            originalSelectedStartTime: '',
            partnerInput: '',
            programManagerSelect: '',
            projectCreatedBy: '',
            projectNameInput: '',
            projectNumberInput: '',
            projectNumberType: 'PO',
            projectStatusName: 'PO Quoted',
            projectTypeSelect: '',
            selectedStartTime: '',
            selectedEndTime: '',

            prevProjectStatus: -1,
            projectId: NaN,
            projectStatus: 1,

            allExistingProjectsName: [],
            allOfProjectEndTime: [],
            allOfProjectStartTime: [],
            labSelectOptions: [],
            labProjectsToRender: [],
            programManagerSelectOptions: [],
            projectDataArray: [],
            projectNumberTypeOptions: [{ value: 'PO', label: 'PO' }, { value: 'Quote Number', label: 'Quote Number' }],
            projectTypeSelectOptions: [{ value: 'Industry', label: 'Industry' }, { value: 'DoD', label: 'DoD' }, { value: 'FAA', label: 'FAA' }, { value: 'Other', label: 'Other' }],
        }

        this.calendarRef = React.createRef();
    }

    async componentDidMount() {
        await this.props.getProjectEventsData();
        await this.props.getProgramManager();
        await this.props.getLabs();

        await this.setState({ intialLoadBool: true })

       setInterval(async () => {
            await this.props.getProjectEventsData();
            await this.props.getProgramManager();
            await this.props.getLabs();
        }, 10000);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.programManagersStatus !== this.props.programManagersStatus) {
            if (this.props.programManagersStatus === 'Finished') {
                const array = [];

                for (let i = 0; i < this.props.programManagers.length; i++) {
                    array[i] = { value: this.props.programManagers[i].id, label: this.props.programManagers[i].name }
                }

                await this.setState({ programManagerSelectOptions: array })
            }
        }

        if (prevProps.labsData !== this.props.labsData) {
            if (this.props.labsDataStatus === 'Finished') {
                const array = [];

                for (let i = 0; i < this.props.labsData.length; i++) {
                    array[i] = { value: this.props.labsData[i].id, label: `${this.props.labsData[i].labName} (${this.props.labsData[i].labCode})` }
                }

                await this.setState({ labSelectOptions: array })
            }
        }

        if (prevProps.projectData !== this.props.projectData) {
            const intialBool = false;
            if (this.props.projectDataStatus === 'Finished') {
                let allExistingProjectsName = [];

                this.props.projectData.map((lab) => {
                    lab.projectDetails.map((project) => {
                        allExistingProjectsName.push({ value: project.id, label: project.name })
                    })
                })

                await this.setState({ allExistingProjectsName });

                //Error is here and at 9somethig
                if (this.state.intialLoadBool) {
                    this.setState({ firstLabCodeToRender: this.props.projectData[0].projectDetails[0].lab.labCode, intialLoadBool: false })
                    //this.setState({ labProjectsToRender: this.props.projectData, firstLabCodeToRender: this.props.projectData[0].projectDetails[0].lab.labCode })
                    await this.props.addFilteredData(this.props.projectData[0])
                }

                //this.setState({ firstLabCodeToRender: this.props.projectData[0].projectDetails[0].lab.labCode })
                //this.setState({ labProjectsToRender: this.props.projectData, firstLabCodeToRender: this.props.projectData[0].projectDetails[0].lab.labCode })
            }
        }
    }

    // Function definiton with passing two arrays
    findCommonElement = (array1, array2) => {
        // Loop for array1
        for (let i = 0; i < array1.length; i++) {

            // Loop for array2
            for (let j = 0; j < array2.length; j++) {

                // Compare the element of each and
                // every element from both of the
                // arrays
                if (array1[i] === array2[j]) {

                    // Return if common element found
                    return true;
                }
            }
        }
        // Return if no common element exist
        return false;
    }

    convertDateToTimeString = (date) => {
        return (`${new Date(date).toISOString().slice(0, 10)}T${new Date(date).getHours()
            .toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            })}:${new Date(date).getMinutes()
                .toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })}:00`
        );
    }


    onCalendarProjectSelect = ({ start, end }) => {
        this.setState({
            showCreateProjectModel: true,
            selectedStartTime: this.convertDateToTimeString(start),
            selectedEndTime: this.convertDateToTimeString(end),
        })
    }

    isAnOverlapEvent(event1Start, event1End, array) {
        for (let i = 0; i < array.length; i++) {
            const event2 = array[i];

            // start-time in between any of the events
            if (event1Start > event2.start && event1Start < event2.end) {
                return true;
            }

            //end-time in between any of the events
            if (event1End > event2.start && event1End < event2.end) {
                return true;
            }

            //any of the events in between/on the start-time and end-time
            if (event1Start <= event2.start && event1End >= event2.end) {
                return true;
            }
            
        }
        return false;
    }

    doesEventOverlapInLab = (labId, newStart, newEnd, oldStart, oldEnd) => {
        let labTimeArray = [];

        this.props.projectData.map((lab) => {
            lab.projectDetails.map((proj) => {
                if (parseInt(labId) === proj.labId) {
                    proj.startTime.map((start, i) => {
                        proj.endTime.map((end, j) => {
                            if (i === j) {
                                if (oldStart !== null && oldEnd !== null) {
                                    if (new Date(oldStart).toISOString() !== new Date(start).toISOString() && new Date(oldEnd).toISOString() !== new Date(end).toISOString()) {
                                        labTimeArray.push({ start: new Date(start), end: new Date(end) })
                                    } 
                                } else {
                                    labTimeArray.push({ start: new Date(start), end: new Date(end) })
                                }
                            }
                        })
                    })
                }
            })
        });

        return this.isAnOverlapEvent(new Date(newStart), new Date(newEnd), labTimeArray)
    }

    projectDetailsElement = (edit, create) => {
        return (
            <div className="row">
                <div className="col-12">
                    <Input
                        isError={this.state.projectNameInputError}
                        id="projectNameInput"
                        onChange={this.onProjectDetailInputChange}
                        value={this.state.projectNameInput}
                        labelTitle="Project Name"
                        type="text"
                        readOnly={!edit && !create}
                    />
                </div>
                <div className="col-xs-12 col-md-6">
                    <Input
                        isError={this.state.selectedStartTimeError}
                        id="selectedStartTime"
                        onChange={(e) => this.onProjectDetailInputChange(e)}
                        value={this.state.selectedStartTime}
                        labelTitle="Start Date"
                        type="datetime-local"
                        readOnly={!edit && !create}
                    />
                </div>
                <div className="col-xs-12 col-md-6">
                    <Input
                        isError={this.state.selectedEndTimeError}
                        id="selectedEndTime"
                        onChange={(e) => this.onProjectDetailInputChange(e)}
                        value={this.state.selectedEndTime}
                        labelTitle="End Date"
                        type="datetime-local"
                        readOnly={!edit && !create}
                    />
                </div>
                <div className="col-12">
                    <Select
                        isError={this.state.labSelectError}
                        arrayOfOptions={this.state.labSelectOptions}
                        id="labSelect"
                        onChange={this.onProjectDetailInputChange}
                        labelTitle="Lab"
                        className="ltGrayBG"
                        selected={this.state.labSelect}
                        disabled={!create}
                        placeholderOption
                    />
                </div>
                {/*<TextArea
                    isError={this.state.projectDescriptionInputError}
                    id="projectDescriptionInput"
                    onChange={this.onProjectDetailInputChange}
                    value={this.state.projectDescriptionInput}
                    labelTitle="Brief Project Description"
                />*/}
                <div className="col-12">
                    <Input
                        isError={this.state.projectNumberInputError}
                        id="projectNumberInput"
                        onChange={this.onProjectDetailInputChange}
                        value={this.state.projectNumberInput}
                        labelTitle="Project Number"
                        type="text"
                        onPrependSelectChange={this.onProjectDetailInputChange}
                        prependSelectId="projectNumberType"
                        prependSelectOptions={this.state.projectNumberTypeOptions}
                        selected={this.state.projectNumberType}
                        readOnly={!edit && !create}
                        prependSelectDisable={!edit && !create}
                    />
                </div>
                <div className="col-12">
                    <Select
                        isError={this.state.projectTypeSelectError}
                        arrayOfOptions={this.state.projectTypeSelectOptions}
                        id="projectTypeSelect"
                        onChange={this.onProjectDetailInputChange}
                        labelTitle="Project Type"
                        className="ltGrayBG"
                        selected={this.state.projectTypeSelect}
                        disabled={!edit && !create}
                        placeholderOption
                    />
                </div>
                <div className="col-12">
                    <Input
                        isError={this.state.partnerInputError}
                        id="partnerInput"
                        onChange={this.onProjectDetailInputChange}
                        value={this.state.partnerInput}
                        labelTitle="Industry Partner"
                        type="text"
                        readOnly={!edit && !create}
                    />
                </div>
                <div className="col-12">
                    <Select
                        isError={this.state.programManagerSelectError}
                        arrayOfOptions={this.state.programManagerSelectOptions}
                        id="programManagerSelect"
                        onChange={this.onProjectDetailInputChange}
                        labelTitle="Program Manager"
                        className="ltGrayBG"
                        selected={this.state.programManagerSelect}
                        disabled={!edit && !create}
                        placeholderOption
                    />
                </div>
                <div className="col-12 mb-3">
                    {/*<div className="multiStepperHeader">Project Status</div>*/}
                    <MultiStepper
                        label1="PO Quoted"
                        onCircle1Click={() => edit || create ? this.setState({ projectStatus: 1, prevProjectStatus: this.state.projectStatus, projectStatusName: 'PO Quoted' }) : null}
                        onCircle2Click={() => edit || create ? this.setState({ projectStatus: 2, prevProjectStatus: this.state.projectStatus, projectStatusName: 'PO Recieved' }) : null}
                        onCircle3Click={() => edit || create ? this.setState({ projectStatus: 3, prevProjectStatus: this.state.projectStatus, projectStatusName: 'Completed' }) : null}
                        label2="PO Recieved"
                        label3="Completed"
                        prevMultiStepNum={this.state.prevProjectStatus}
                        multiStepNum={this.state.projectStatus}
                        isDkColor={false}
                    />
                </div>
                {edit ?
                    <div className="col-12 mb-2 projectDetailFooter">
                        <h6 className="d-inline-block order-2 col-md-9 col-12 dkGray mb-0">Created by <i>{this.state.projectCreatedBy}</i></h6>
                        <button
                            type="button"
                            className="col-md-3 projectDetailFooterDeleteBtn col-12 renderFadeIn btn redBG white"
                            onClick={() => this.setState({ showProjectDeleteModal: true })}
                        >Delete</button>
                    </div>
                    : null
                } 
            </div>    
        )
    }

    createProjectModel = () => {
        if (this.state.showCreateProjectModel) {
            const modalBody = () => {
                if (!this.state.isNewProject) {
                    return (
                        <div className="row">
                            <div className="col-6">
                                <Input
                                    isError={this.state.selectedStartTimeError}
                                    id="selectedStartTime"
                                    onChange={(e) => this.onProjectDetailInputChange(e)}
                                    value={this.state.selectedStartTime}
                                    labelTitle="Start Date"
                                    type="datetime-local"
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    isError={this.state.selectedEndTimeError}
                                    id="selectedEndTime"
                                    onChange={(e) => this.onProjectDetailInputChange(e)}
                                    value={this.state.selectedEndTime}
                                    labelTitle="End Date"
                                    type="datetime-local"
                                />
                            </div>
                            <div className="col-12">
                                <SearchDropDown
                                    isError={this.state.existingProjectsError}
                                    id="existingProjects"
                                    onChange={(e) => this.onProjectDetailInputChange(e)}
                                    value={this.state.existingProjects}
                                    labelTitle="Existing Projects"
                                    arrayOfOptions={this.state.allExistingProjectsName}
                                />
                            </div>
                            <div className="col-12">
                                <h6 className="d-inline-block">Don't have an existing project?
                                    <div
                                        className="d-inline-block pointer yellow ps-1"
                                        onClick={() => { this.onClearNCloseModalValues(false); this.setState({ isNewProject: true }); }}
                                    >
                                        Create a project.
                                    </div>
                                </h6>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="row">
                            {this.projectDetailsElement(false, true)}
                            <div className="col-12">
                                <h6 className="d-inline-block">Have an existing project?
                                    <div
                                        className="d-inline-block pointer yellow ps-1"
                                        onClick={() => { this.onClearNCloseModalValues(false); this.setState({ isNewProject: false }); }}
                                    >
                                        Select a project.
                                    </div>
                                </h6>
                            </div>
                        </div>  
                    );
                }
            }

            return (
                <Modal
                    modalHeaderTitle="Create a New Project"
                    modalBody={modalBody()}
                    modalFooterBtnText="Add Project"
                    onModalCloseClick={() => this.onClearNCloseModalValues(true)}
                    onModalFooterBtnClick={() => this.onAddProjectSubmit()}
                    colorBtnClass="yellowBG"
                    onModalSecondaryFooterBtnClick={() => this.onClearNCloseModalValues(true)}
                    modalSecondaryFooterBtnText="Cancel"
                    secondaryColorBtnClass="redBG white" white
                />
            );
        }
    }

    onClearNCloseModalValues = (isClose) => {
        // isClose is bool to see if to close modal
        this.setState({
            existingProjects: '',
            projectNameInput: '',
            projectTypeSelect: '',
            partnerInput: '',
            programManagerSelect: '',
            projectStatusName: '',
            projectNumberType: 'PO',
            projectNumberInput: '',
            projectStatus: 1,
            prevProjectStatus: -1,
            projectStatusName: 'PO Quoted',
            labSelect: '',
            projectCreatedBy: '',
            projectId: NaN,
            selectedStartTimeError: false,
            selectedEndTimeError: false,
            existingProjectsError: false,
            projectNameInputError: false,
            labSelectError: false,
            projectNumberInputError: false,
            partnerInputError: false,
            projectTypeSelectError: false,
            programManagerSelectError: false,
        })

        if (this.state.showCreateProjectModel && isClose) {
            this.setState({
                showCreateProjectModel: false,
                isNewProject: false,
                selectedStartTime: '',
                selectedEndTime: '',
            })
        } else if (this.state.showProjectInfoModal && isClose) {
            this.setState({
                showProjectInfoModal: false,
                selectedStartTime: '',
                selectedEndTime: '',
            })
        }
    }

    onProjectDetailInputChange = async (e) => {
        const inputName = e.target.id;
        const inputNameError = `${e.target.id}Error`;
        const value = e.target.value;

        await this.setState({ [inputName]: value });

        if (inputName !== '') {
            await this.setState({ [inputNameError]: false })
        }

        if (inputName === 'labSelect' || inputName === 'existingProjects') {
            this.setState({ labSelectError: '', selectedStartTimeError: false, selectedEndTimeError: false })
        }
    }

    onAddProjectSubmit = async () => {
        if (!this.state.isNewProject) {
            if (this.state.selectedStartTime === '') {
                await this.setState({ selectedStartTimeError: true });
                this.props.onAddPostMessage({ message: ['Start Time is Required.'], color: 'redBG' })
            }

            if (this.state.selectedEndTime === '') {
                await this.setState({ selectedEndTimeError: true });
                this.props.onAddPostMessage({ message: ['End Time is Required.'], color: 'redBG' })
            }

            if (this.state.existingProjects === '') {
                await this.setState({ existingProjectsError: true });
                this.props.onAddPostMessage({ message: ['Project is Required.'], color: 'redBG' })
            }


            if (new Date(this.state.selectedEndTime) <= new Date(this.state.selectedStartTime)) {
                await this.setState({ selectedStartTimeError: true });
                this.props.onAddPostMessage({ message: ['Start Time cannot be greater than or equal to End Time.'], color: 'redBG' })
            }

            if (!this.state.existingProjectsError && !this.state.selectedStartTimeError && !this.state.selectedEndTimeError) {

                this.props.projectData.map(async (lab) => {
                    lab.projectDetails.map(async (project) => {
                        if (parseInt(this.state.existingProjects) === project.id) {
                            if (!this.doesEventOverlapInLab(project.labId, this.state.selectedStartTime, this.state.selectedEndTime, null, null)) {
                                let startArr = [this.state.selectedStartTime], endArr = [this.state.selectedEndTime]

                                await this.props.editLabProject(
                                    this.props.history,
                                    startArr.concat(project.startTime),
                                    endArr.concat(project.endTime),
                                    project.name,
                                    project.projectNum,
                                    project.type.type,
                                    project.partner,
                                    project.projectManager.id,
                                    project.status,
                                    project.labId,
                                    project.id
                                );

                                await this.AddLabButton(project.lab.labName, project.lab.labCode)
                                this.onClearNCloseModalValues(true);
                            } else {
                                this.setState({ selectedStartTimeError: true, selectedEndTimeError: true })
                                this.props.onAddPostMessage({ message: ['Failed to add project. Project selected is overlapping another project in selected lab.'], color: 'redBG' })
                            }
                        }
                    })
                })
            }

        } else {
            if (this.state.projectNameInput === '') {
                await this.setState({ projectNameInputError: true });
                this.props.onAddPostMessage({ message: ['Project Name is Required.'], color: 'redBG' })
            }

            if (this.state.selectedStartTime === '') {
                await this.setState({ selectedStartTimeError: true });
                this.props.onAddPostMessage({ message: ['Start Time is Required.'], color: 'redBG' })
            }

            if (this.state.selectedEndTime === '') {
                await this.setState({ selectedEndTimeError: true });
                this.props.onAddPostMessage({ message: ['End Time is Required.'], color: 'redBG' })
            }

            if (this.state.labSelect === '') {
                await this.setState({ labSelectError: true });
                this.props.onAddPostMessage({ message: ['Lab is Required.'], color: 'redBG' })
            }

            if (this.state.projectNumberInput === '') {
                await this.setState({ projectNumberInputError: true });
                this.props.onAddPostMessage({ message: ['Project Number is Required.'], color: 'redBG' })
            }

            if (this.state.partnerInput === '') {
                await this.setState({ partnerInputError: true });
                this.props.onAddPostMessage({ message: ['Industry Partner is Required.'], color: 'redBG' })
            }

            if (this.state.projectTypeSelect === '') {
                await this.setState({ projectTypeSelectError: true });
                this.props.onAddPostMessage({ message: ['Project Type is Required.'], color: 'redBG' })
            }

            if (this.state.programManagerSelect === '') {
                await this.setState({ programManagerSelectError: true });
                this.props.onAddPostMessage({ message: ['Project Manager is Required.'], color: 'redBG' })
            }

            if (new Date(this.state.selectedEndTime) <= new Date(this.state.selectedStartTime)) {
                await this.setState({ selectedStartTimeError: true });
                this.props.onAddPostMessage({ message: ['Start Time cannot be greater than or equal to End Time.'], color: 'redBG' })
            }


            if (!this.doesEventOverlapInLab(this.state.labSelect, this.state.selectedStartTime, this.state.selectedEndTime, null, null)) {
                if (!this.state.selectedStartTimeError &&
                    !this.state.selectedEndTimeError &&
                    !this.state.labSelectError &&
                    !this.state.programManagerSelectError &&
                    !this.state.projectTypeSelectError &&
                    !this.state.projectNameInputError &&
                    !this.state.projectNumberInputError &&
                    !this.state.partnerInputError
                ) {
                    await this.props.createLabProject(
                        this.props.history,
                        [this.state.selectedStartTime],
                        [this.state.selectedEndTime],
                        this.state.projectNameInput,
                        `${this.state.projectNumberType}-${this.state.projectNumberInput}`,
                        this.state.projectTypeSelect,
                        this.state.partnerInput,
                        this.state.programManagerSelect,
                        this.state.projectStatusName.replace(/\s/g, ''),
                        this.state.labSelect
                    );

                    const lab = this.props.labsData.filter((lab) => lab.id === parseInt(this.state.labSelect));

                    await this.AddLabButton(lab[0].labName, lab[0].labCode)
                    this.onClearNCloseModalValues(true);
                }
            } else {
                this.setState({ selectedStartTimeError: true, selectedEndTimeError: true, labSelect: '', labSelectError: true })
                this.props.onAddPostMessage({ message: ['Failed to create project. Attempted timeslot is overlapping another project in selected lab.'], color: 'redBG' })
            }
        }
    }

    onProjectEventDetailClick = (e) => {
        this.onClearNCloseModalValues(false);

        this.props.projectData.map((lab) => {
            lab.projectDetails.map((project) => {
                if (project.id === parseInt(e.event.id)) {
                    console.log(project)
                    this.setState({
                        showProjectInfoModal: !this.state.showProjectInfoModal,
                        projectId: project.id,
                        projectNameInput: project.name,
                        projectTypeSelect: project.type.type,
                        partnerInput: project.partner,
                        programManagerSelect: project.projectManager.id,
                        projectStatusName: project.status.slice(0, 2) === "PO" ? `${project.status.slice(0, 2)} ${project.status.slice(2, project.status.length)}` : project.status,
                        projectNumberType: project.projectNum.slice(0, project.projectNum.indexOf('-')),
                        projectNumberInput: project.projectNum.slice(project.projectNum.indexOf('-') + 1, project.projectNum.length),
                        projectStatus: project.status === "POQuoted" ? 1 : project.status === "Completed" ? 3 : 2,
                        projectStatusName: project.status === "POQuoted" ? 'PO Quoted' : project.status === "Completed" ? 'Completed' : 'PO Recieved',
                        labSelect: project.labId,
                        allOfProjectStartTime: project.startTime,
                        allOfProjectEndTime: project.endTime,
                        selectedStartTime: this.convertDateToTimeString(e.event.start),
                        selectedEndTime: this.convertDateToTimeString(e.event.end),
                        originalSelectedStartTime: this.convertDateToTimeString(e.event.start),
                        originalSelectedEndTime: this.convertDateToTimeString(e.event.end),
                        projectCreatedBy: project.createdBy || 'NULL'
                    })
                }

            })
        })
    }

    onProjectEventDrop = (e) => {
        this.props.projectData.map((lab) => {
            lab.projectDetails.map((project) => {
                if (project.id === parseInt(e.event.id)) {
                    if (this.doesEventOverlapInLab(project.labId, e.event.start, e.event.end, e.oldEvent.start, e.oldEvent.end)) {
                        console.log("wer", this.props.projectData, e)
                        e.revert();
                    } else if (project.startTime.includes(e.oldEvent.start) && project.endTime.includes(e.oldEvent.end) ) {
                        console.log("wsdgdfgdfgdffdgfddfsdfer", this.props.projectData, e);
                        e.revert();
                    } else {
                        console.log(this.props.projectData, e)
                    }
                }
            });
        });
    }

    showProjectEventDetailsModel = () => {
        if (this.state.showProjectInfoModal) {
            return (
                <Modal
                    modalHeaderTitle={this.state.projectNameInput}
                    modalBody={this.projectDetailsElement(this.props.canAddProject && this.props.canShowProject, false)}
                    modalFooterBtnText={this.props.canAddProject && this.props.canShowProject ? 'Save Changes' : 'Close'}
                    onModalCloseClick={() => this.onClearNCloseModalValues(true)}
                    onModalFooterBtnClick={() => this.props.canAddProject && this.props.canShowProject ? this.onEditProjectSumbit() : this.onClearNCloseModalValues(true)}
                    colorBtnClass="yellowBG"
                    onModalSecondaryFooterBtnClick={() => this.props.canAddProject && this.props.canShowProject ? this.onClearNCloseModalValues(true) : null}
                    modalSecondaryFooterBtnText={this.props.canAddProject && this.props.canShowProject ? 'Cancel' : ''}
                    secondaryColorBtnClass={this.props.canAddProject && this.props.canShowProject ? 'redBG white' : ''}
                />
            );
        }
    }

    onEditProjectSumbit = async () => {
        let startArr = this.state.allOfProjectStartTime, endArr = this.state.allOfProjectEndTime;

        if (this.state.projectNameInput === '') {
            await this.setState({ projectNameInputError: true });
            this.props.onAddPostMessage({ message: ['Project Name is Required.'], color: 'redBG' })
        }

        if (this.state.selectedStartTime === '') {
            await this.setState({ selectedStartTimeError: true });
            this.props.onAddPostMessage({ message: ['Start Time is Required.'], color: 'redBG' })
        }

        if (this.state.selectedEndTime === '') {
            await this.setState({ selectedEndTimeError: true });
            this.props.onAddPostMessage({ message: ['End Time is Required.'], color: 'redBG' })
        }

        if (this.state.labSelect === '') {
            await this.setState({ labSelectError: true });
            this.props.onAddPostMessage({ message: ['Lab is Required.'], color: 'redBG' })
        }

        if (this.state.projectNumberInput === '') {
            await this.setState({ projectNumberInputError: true });
            this.props.onAddPostMessage({ message: ['Project Number is Required.'], color: 'redBG' })
        }

        if (this.state.partnerInput === '') {
            await this.setState({ partnerInputError: true });
            this.props.onAddPostMessage({ message: ['Industry Partner is Required.'], color: 'redBG' })
        }

        if (this.state.projectTypeSelect === '') {
            await this.setState({ projectTypeSelectError: true });
            this.props.onAddPostMessage({ message: ['Project Type is Required.'], color: 'redBG' })
        }

        if (this.state.programManagerSelect === '') {
            await this.setState({ programManagerSelectError: true });
            this.props.onAddPostMessage({ message: ['Program Manager is Required.'], color: 'redBG' })
        }

        if (new Date(this.state.selectedEndTime) <= new Date(this.state.selectedStartTime)) {
            await this.setState({ selectedStartTimeError: true });
            this.props.onAddPostMessage({ message: ['Start Time cannot be greater than or equal to End Time.'], color: 'redBG' })
        }

        if (!this.doesEventOverlapInLab(this.state.labSelect, this.state.selectedStartTime, this.state.selectedEndTime, this.state.originalSelectedStartTime, this.state.originalSelectedEndTime)) {
            if (this.state.selectedStartTime !== this.state.originalSelectedStartTime) {
                startArr = startArr.filter((start) => start !== this.state.originalSelectedStartTime);

                if (startArr.length !== this.state.allOfProjectEndTime.length) {
                    startArr.push(this.state.selectedStartTime)
                } else {
                    this.setState({ selectedStartTimeError: true })
                    this.props.onAddPostMessage({ message: ['An error occured with the start event time. Try again later.'], color: 'redBG' })
                }
            }

            if (this.state.selectedEndTime !== this.state.originalSelectedEndTime) {
                endArr = endArr.filter((end) => end !== this.state.originalSelectedEndTime)

                if (endArr.length !== this.state.allOfProjectEndTime.length) {
                    endArr.push(this.state.selectedEndTime)
                } else {
                    this.setState({ selectedEndTimeError: true })
                    this.props.onAddPostMessage({ message: ['An error occured with the end event time. Try again later.'], color: 'redBG' })
                }

            }
            if (!this.state.selectedStartTimeError &&
                !this.state.selectedEndTimeError &&
                !this.state.labSelectError &&
                !this.state.programManagerSelectError &&
                !this.state.projectTypeSelectError &&
                !this.state.projectNameInputError &&
                !this.state.projectNumberInputError &&
                !this.state.partnerInputError
            ) {
                await this.props.editLabProject(
                    this.props.history,
                    startArr,
                    endArr,
                    this.state.projectNameInput,
                    `${this.state.projectNumberType}-${this.state.projectNumberInput}`,
                    this.state.projectTypeSelect,
                    this.state.partnerInput,
                    this.state.programManagerSelect,
                    this.state.projectStatusName.replace(/\s/g, ''),
                    this.state.labSelect,
                    this.state.projectId
                );

                this.setState({ showProjectInfoModal: false });
            }
        } else {
            this.setState({ selectedStartTimeError: true, selectedEndTimeError: true })
            this.props.onAddPostMessage({ message: ['Failed to create timeslot. Attempted timeslot is overlapping another timeslot.'], color: 'redBG' })
        }
    }

    onDragNResizeChange = async (e) => {
        this.onClearNCloseModalValues(false);

        let projectDetails = null;
        //find and set the project that was selected
        this.props.projectData.map(lab => {
            return lab.projectDetails.map(project => {
                if (project.id === parseInt(e.event.id)) {
                    projectDetails = project;
                }
            })
        });

        if (!this.doesEventOverlapInLab(projectDetails.labId, e.event.start, e.event.end, e.oldEvent.start, e.oldEvent.end, e.event.id)) {
            let startArr = projectDetails.startTime, endArr = projectDetails.endTime;
            // check to see if they resized the start time or end time
            if (e.event.start !== e.oldEvent.start) {
                //remove the old time before timeslot was resized/dragged
                startArr = startArr.filter((start) => start !== this.convertDateToTimeString(e.oldEvent.start));
                //double check the time was removed
                if (startArr.length !== projectDetails.startTime.length) {
                    //add new timeslot that was dragged/resized
                    startArr.push(this.convertDateToTimeString(e.event.start))
                } else {
                    //else something went wrong
                    this.setState({ selectedStartTimeError: true })
                    this.props.onAddPostMessage({ message: ['An error occured with the start event time. Try again later.'], color: 'redBG' })
                }
            }
            //do the same as the start
            if (e.event.end !== e.oldEvent.end) {
                endArr = endArr.filter((end) => end !== this.convertDateToTimeString(e.oldEvent.end));

                if (endArr.length !== projectDetails.endTime.length) {
                    endArr.push(this.convertDateToTimeString(e.event.end))
                } else {
                    this.setState({ selectedEndTimeError: true })
                    this.props.onAddPostMessage({ message: ['An error occured with the end event time. Try again later.'], color: 'redBG' })
                }

            }
            //make the changes to the api if no errors or overlap
            if (!this.state.selectedEndTimeError && !this.state.selectedStartTimeError) {
                await this.props.editLabProject(
                    this.props.history,
                    startArr,
                    endArr,
                    projectDetails.name,
                    projectDetails.projectNum,
                    projectDetails.type.type,
                    projectDetails.partner,
                    projectDetails.projectManager.id,
                    projectDetails.status,
                    projectDetails.labId,
                    projectDetails.id,
                );
            }

        } else {
            this.props.onAddPostMessage({ message: ['Failed to move project. Attempted timeslot is overlapping another timeslot.'], color: 'redBG' })
        }
    }

    onProjectDeleteModal = () => {
        let projectDetails = null;
        this.props.projectData.map(lab => {
            return lab.projectDetails.map(project => {
                if (project.id === this.state.projectId) {
                    projectDetails = project;
                }
            })
        });
        

        const onRemoveTimeBlockClick = () => {
            const newStartTimes = projectDetails.startTime.filter(start => start !== this.state.selectedStartTime);
            const newEndTimes = projectDetails.endTime.filter(end => end !== this.state.selectedEndTime);

            this.props.editLabProject(
                this.props.history,
                newStartTimes,
                newEndTimes,
                projectDetails.name,
                projectDetails.projectNum,
                projectDetails.type.type,
                projectDetails.partner,
                projectDetails.projectManager.id,
                projectDetails.status,
                projectDetails.labId,
                projectDetails.id,
            );

            //this.setState({ showProjectDeleteModal: false, showProjectInfoModal: false });
        }

        if (this.state.showProjectDeleteModal && projectDetails.startTime.length > 1) {
            return (
                <Modal
                    modalHeaderTitle={this.state.projectNameInput}
                    modalBody={`Would you like to delete the entire project ${this.state.projectNameInput} or the time block ${new Date(this.state.selectedStartTime)} -  ${new Date(this.state.selectedEndTime)}?`}
                    modalFooterBtnText="DELETE TIME BLOCK"
                    onModalCloseClick={() => this.setState({ showProjectDeleteModal: false  })}
                    onModalFooterBtnClick={() => { onRemoveTimeBlockClick(); this.setState({ showProjectDeleteModal: false, showProjectInfoModal: false }); }}
                    onModalSecondaryFooterBtnClick={() => { this.props.deleteLabProject(this.props.history, this.state.projectId); this.setState({ showProjectDeleteModal: false, showProjectInfoModal: false }); }}
                    modalSecondaryFooterBtnText="DELETE PROJECT"
                    secondaryColorBtnClass="redBG white"
                    colorBtnClass="redBG white"
                />
            );
        } else if (this.state.showProjectDeleteModal && projectDetails.startTime.length === 1) {
            return (
                <Modal
                    modalHeaderTitle={this.state.projectNameInput}
                    modalBody={`Are you sure you would like to delete the entire project ${this.state.projectNameInput}?`}
                    modalFooterBtnText="Yes"
                    onModalCloseClick={() => this.setState({ showProjectDeleteModal: false })}
                    onModalFooterBtnClick={() => { this.props.deleteLabProject(this.props.history, this.state.projectId); this.setState({ showProjectDeleteModal: false, showProjectInfoModal: false }); }}
                    onModalSecondaryFooterBtnClick={() => this.setState({ showProjectDeleteModal: false })}
                    modalSecondaryFooterBtnText="No"
                    secondaryColorBtnClass="yellowBG"
                    colorBtnClass="redBG white"
                />
            );
        }
    }

    renderEvents = () => {
        let allEvents = [];

        this.props.filteredProjectData && this.props.filteredProjectData.map((project) => {
            project.projectDetails.map((project) => {
                project.startTime.map((startTime, i) => {
                    project.endTime.map((endTime, j) => {
                        if (i === j) {
                            /*
                                //startTime: '08:00',
                                //endTime: '10:00',
                                //daysOfWeek: [2, 4],
                                //startRecur: new Date("September 20, 2021"),
                                //endRecur: new Date("October 20, 2021"),

                             */
                            allEvents.push({
                                start: new Date(startTime),
                                end: new Date(endTime),
                                title: project.name,
                                color: project.lab.color,
                                textColor: project.lab.textColor,
                                id: project.id
                            });
                        }
                    });
                });
            });
        });

        return allEvents;
    }

    onFilterBtnClick = async (btnName, btnCode) => {
        const filteredData = this.props.filteredProjectData.filter(lab => lab.labName === btnName);
        if (filteredData.length > 0) {
            const array = this.props.filteredProjectData.filter(lab => lab.labName !== btnName)

            this.calendarRef.current.classList.remove(`${btnCode}Selected`);
            this.props.removeFilteredData(btnName)
            await this.setState({ labProjectsToRender: array })
        } else {
            this.AddLabButton(btnName, btnCode)
        }
    }

    AddLabButton = (btnName, btnCode) => {
        const selectedArray = this.props.projectData.filter(lab => lab.labName === btnName);

        if (this.props.filteredProjectData.filter((filteredLab) => filteredLab.labName === selectedArray[0].labName).length === 0) {
            this.props.addFilteredData(selectedArray[0])
        }

        this.calendarRef.current.classList.add(`${btnCode}Selected`);
    }

    renderCalendarBtnStyle = () => {
        let calendarBtnColorStyle = '';

        this.props.labsData.map((lab) => {
            calendarBtnColorStyle = calendarBtnColorStyle.concat(
                `.${lab.labCode}Selected .${lab.labCode}Btn {
                    background: ${lab.color} !important;
                    color: ${lab.textColor} !important;
                    opacity: .85 !important;
                }
                
                @media print {
                    .${lab.labCode}Selected .${lab.labCode}Btn {
                        display: inline-block;
                    }
                }`
            );
        });

        return <style>{calendarBtnColorStyle}</style>
    }

    render() {
        return (
            <div id="calendarPage" className="m-md-5 renderFadeIn">
                <ActionNotifications msgArray={this.props.messages} removeMessageFunc={this.props.onRemovePostMessage} />
                <div ref={this.calendarRef} className={`row g-0 fullCalendar ${this.state.firstLabCodeToRender}Selected`}>
                    {this.renderCalendarBtnStyle()}
                    <div id="calendar" className="col-12">
                        <div className="btn-group col-12 calendarBtnWrapper mb-3">
                            {this.props.labsData.map((lab, i) =>
                                <button key={i} type="button" className={`calendarBtn col ${lab.labCode}Btn`} onClick={() => this.onFilterBtnClick(lab.labName, lab.labCode)}>{lab.labCode}</button>
                            )}
                        </div>
                    </div>
                    <div id="calendar" className="col-12">
                        <FullCalendar
                            customButtons={{
                                print: {
                                    text: 'Print',
                                    click: () => window.print()
                                },
                            }}
                            headerToolbar={{
                                left: 'title',
                                right: 'prev,next today,dayGridMonth,timeGridWeek,timeGridDay print'
                            }}
                            scrollTime={`${new Date().getHours()}:00`}
                            events={this.renderEvents()}
                            select={this.onCalendarProjectSelect}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialView="timeGridWeek"
                            eventClick={this.props.canShowProject ? this.onProjectEventDetailClick : null}
                            selectable={this.props.canAddProject}
                            //selectOverlap={false}
                            eventResizableFromStart={this.props.canAddProject}
                            eventDurationEditable={this.props.canAddProject}
                            droppable={this.props.canAddProject}
                            editable={this.props.canAddProject}
                            eventChange={this.onDragNResizeChange}
                            //eventDrop={this.onProjectEventDrop}
                            slotMinTime={calendarStartTime}
                            slotMaxTime={calendarEndTime}
                            height="auto"
                            nowIndicator
                            stickyHeaderDates
                        />
                    </div>
                </div>
                {this.createProjectModel()}
                {this.showProjectEventDetailsModel()}
                {this.onProjectDeleteModal()}
            </div>
        );
    }
} 

function mapStateToProps(state) {
    return {
        filteredProjectData: state.ProjectEventsReducer.filteredProjectData,
        editLabProjectStatus: state.CalendarPageReducer.editLabProjectStatus,
        deleteLabProjectStatus: state.CalendarPageReducer.deleteLabProjectStatus,
        postLabProjectStatus: state.CalendarPageReducer.postLabProjectStatus,
        projectDataStatus: state.ProjectEventsReducer.projectDataStatus,
        projectData: state.ProjectEventsReducer.projectData,
        messages: state.ToastMessagesReducer.messages,
        programManagers: state.programManagersReducer.programManagers,
        programManagersStatus: state.programManagersReducer.programManagersStatus,
        labsData: state.LabsReducer.labsData,
        labsDataStatus: state.LabsReducer.labsDataStatus,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getProjectEventsData, getProgramManager, editLabProject, getLabs, createLabProject, deleteLabProject, onRemovePostMessage, onAddPostMessage, addFilteredData, removeFilteredData }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);