import {
	FETCH_PROJECT_DATA,
	ON_FETCH_PROJECT_DATA_START,
	ON_FETCH_PROJECT_DATA_SUCCESS,
    ON_FETCH_PROJECT_DATA_FAILED,
    REMOVE_FILTERED_PROJECT_DATA,
    FILTER_PROJECT_DATA
} from '../constants/projectEventsConstants';

export const addFilteredData = (data) => {
    return ({
        type: FILTER_PROJECT_DATA,
        data
    });
};

export const removeFilteredData = (data) => {
    return ({
        type: REMOVE_FILTERED_PROJECT_DATA,
        data
    });
};

export const getProjectEventsData = () => {
    return ({
        type: FETCH_PROJECT_DATA,
    });
}

export const onGetProjectEventsDataStart = () => {
    return ({
        type: ON_FETCH_PROJECT_DATA_START,
    });
}

export const onGetProjectEventsDataFailed = () => {
    return ({
        type: ON_FETCH_PROJECT_DATA_FAILED,
    });
}

export const onGetProjectEventsDataSuccess = (data) => {
    return ({
        type: ON_FETCH_PROJECT_DATA_SUCCESS,
        data
    });
}