import {
	FETCH_LAB_DATA,
	ON_FETCH_LAB_DATA_START,
	ON_FETCH_LAB_DATA_SUCCESS,
	ON_FETCH_LAB_DATA_FAILED
} from '../constants/labsConstants';

export const getLabs = () => {
    return ({
        type: FETCH_LAB_DATA,
    });
}

export const onGetLabsDataStart = () => {
    return ({
        type: ON_FETCH_LAB_DATA_START,
    });
}

export const onGetLabsDataFailed = () => {
    return ({
        type: ON_FETCH_LAB_DATA_FAILED,
    });
}

export const onGetLabsDataSuccess = (data) => {
    return ({
        type: ON_FETCH_LAB_DATA_SUCCESS,
        data
    });
}