import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";


import ActionNotifications from '../../component/ActionNotifications';
import Input from '../../component/Input';
import LoadingSpinnerModal from '../../component/LoadingSpinnerModal';
import { onAddPostMessage, onRemovePostMessage, onClearAllMessages } from '../../actions/toastMessagesActions';
import atlasLogoWText from '../../Assets/images/AtlasLogoWText.jpg';
import NIARLogo from '../../Assets/images/NIARLogo.png';
import { requestSecurityCode, validateSecurityCode, changePassword } from './actions';
import { recaptchSiteKey } from '../../ProjectControls'

import './styles.css';

class LogInPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmPassword: '',
            email: '',
            recaptchaValue: '',
            recaptchaValueError: false,
            emailError: false,
            securityCodeError: false,
            passwordError: false,
            confirmPasswordError: false,
            password: '',
            securityCode: '',
            timer: 0,
            isStartRecoverProccessView: true,
            isEnterSecurityCodeView: false,
            isChangePasswordView: false,
            showSpinner: false,
        }
    }

    componentDidMount() {
        this.props.onClearAllMessages()
    }

    async componentDidUpdate(prevProps, prevState) {
        //Timer for expiration of security code. Make sure to change the 120 in the functions too.
        if (prevState.isEnterSecurityCodeView !== this.state.isEnterSecurityCodeView) {
            if (this.state.isEnterSecurityCodeView) {
                setInterval(() => {
                    if (this.state.timer !== 120) {
                        this.setState((prevState) => ({ timer: prevState.timer + 1 }));
                    }
                }, 1000);
            }
        }

        if (prevProps.requestSecurityCodeStatus !== this.props.requestSecurityCodeStatus ||
            prevProps.validateSecurityCodeStatus !== this.props.validateSecurityCodeStatus ||
            prevProps.changePasswordStatus !== this.props.changePasswordStatus) {
            if (this.props.requestSecurityCodeStatus === 'Started' ||
                this.props.validateSecurityCodeStatus === 'Started' ||
                this.props.changePasswordStatus === 'Started') {
                await this.setState({ showSpinner: true })
            } else {
                await this.setState({ showSpinner: false })
            }
        }

        if (prevProps.requestSecurityCodeStatus !== this.props.requestSecurityCodeStatus) {
            if (this.props.requestSecurityCodeStatus === 'Sent') {
                await this.setState({ isStartRecoverProccessView: false, isEnterSecurityCodeView: true })
            }
        }

        if (prevProps.validateSecurityCodeStatus !== this.props.validateSecurityCodeStatus) {
            if (this.props.validateSecurityCodeStatus === 'Validated') {
                await this.setState({ isChangePasswordView: true, isEnterSecurityCodeView: false })
            }
        }

        if (prevProps.changePasswordStatus !== this.props.changePasswordStatus) {
            if (this.props.changePasswordStatus === 'Changed') {
                this.props.onClearAllMessages();
                this.props.history.push('/');
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    formatTime = (time) => {
        return (
            `${String(Math.floor(time / 60)).padStart(2, "0")}:${String(
                time % 60
            ).padStart(2, "0")}`
        );
    }

    onLoginInInputChange = async (e) => {
        const inputName = e.target.id;
        const inputNameError = `${e.target.id}Error`;
        const value = e.target.value;

        await this.setState({ [inputName]: value });

        if (inputName !== '') {
            await this.setState({ [inputNameError]: false })
        }
    }

    onCloseToastClick = (e) => {
        this.props.onRemovePostMessage({ message: [e.target.name], color: e.target.value })
    }

    showSpinner = () => {
        if (this.state.showSpinner) {
            return <LoadingSpinnerModal />;
        }
    }

    startRecoverProccessView = () => {
        if (this.state.isStartRecoverProccessView) {
            const recaptchError = this.state.recaptchaValueError ? 'recaptchError' : '';

            return (
                <div className="renderFadeIn">
                    <div className="mb-4">
                        <h2>Recover Password</h2>
                        <h4>Don't worry, happens to the best of us.</h4>
                    </div>
                    <div>
                        <Input
                            isError={this.state.emailError}
                            id="email"
                            onChange={this.onLoginInInputChange}
                            value={this.state.email}
                            labelTitle="Email Address"
                            className="mb-3"
                            type="text"
                        />
                        <div className="col-12 mb-3">
                            <ReCAPTCHA
                                className={recaptchError}
                                sitekey={recaptchSiteKey}
                                onChange={(value) => this.setState({ recaptchaValue: value, recaptchaValueError: false })}
                                onExpired={() => { this.setState({ recaptchaValueError: true, recaptchaValue: '' }); }}
                                onErrored={() => {
                                    this.setState({ recaptchaValueError: true, recaptchaValue: '' });
                                    this.props.onAddPostMessage({ message: ['An error occured with recaptcha verification. Please try again later.'], color: 'redBG' })
                                }}
                            />
                        </div>
                        <button onClick={() => this.onRequestSecurityCode()} type="submit" className="col-12 button yellowBG mb-3">Email Me Security Code</button>
                    </div>
                </div>
            );
        }
    }

    onRequestSecurityCode = async () => {
        if (this.state.email === '') {
            await this.setState({ emailError: true })
            this.props.onAddPostMessage({ message: ['Email is required.'], color: 'redBG' })
        }

        if (this.state.recaptchaValue === '') {
            await this.setState({ recaptchaValueError: true })
            this.props.onAddPostMessage({ message: ['Recaptcha verification is required.'], color: 'redBG' })
        }

        if (!this.state.emailError && !this.state.recaptchaValueError) {
            await this.setState({ timer: 0 })
            this.props.requestSecurityCode(this.state.email);
        }
    }

    enterSecurityCodeView = () => {
        const startCountDown = 120;
        if (this.state.isEnterSecurityCodeView) {
            return (
                <div className="renderFadeIn">
                    <h4>If this account exists, we have emailed you a security code. Security code expires in</h4>
                    <h1 className="mb-4">{this.state.timer != startCountDown ? this.formatTime(startCountDown - (this.state.timer % startCountDown)) : '00:00'}</h1>
                    <Input
                        isError={this.state.securityCodeError}
                        id="securityCode"
                        onChange={this.onLoginInInputChange}
                        value={this.state.securityCode}
                        labelTitle="Security Code"
                        type="number"
                    />
                    {startCountDown !== this.state.timer ?
                        <>
                            <button onClick={() => this.onValidateSecurityCode()} type="submit" className="col-12 button yellowBG mb-3">
                                Verify Security Code
                            </button>
                            <div className="col-12 text-center">
                                <div className="resendBtn pointer" onClick={() => this.onRequestSecurityCode()}>Resend Security Code</div>
                            </div>
                        </>
                    :
                        <button onClick={() => this.onRequestSecurityCode()} type="submit" className="col-12 button yellowBG mb-3">
                            Resend Security Code
                        </button>
                    }
                    
                </div>
            );
        }
    }

    onValidateSecurityCode = async () => {
        if (this.state.securityCode === '') {
            await this.setState({ securityCodeError: true })
            this.props.onAddPostMessage({ message: ['Security code is required.'], color: 'redBG' })
        }

        if (!this.state.securityCodeError) {
            this.props.validateSecurityCode(this.state.email, this.state.securityCode);
        }
    }

    changePasswordView = () => {
        if (this.state.isChangePasswordView) {
            return (
                <div className="renderFadeIn">
                    <h4 className="mb-4">Create a new password that is at least 6 characters long, contains at least one non alphanumeric character,
                        and contains have at least one digit ('0'-'9').</h4>
                    <Input
                        isError={this.state.passwordError}
                        id="password"
                        onChange={this.onLoginInInputChange}
                        value={this.state.password}
                        labelTitle="Password"
                        type="password"
                    />
                    <Input
                        isError={this.state.confirmPasswordError}
                        id="confirmPassword"
                        onChange={this.onLoginInInputChange}
                        value={this.state.confirmPassword}
                        labelTitle="Confirm Password"
                        type="password"
                    />
                    <button onClick={() => this.onChangePasswordSubmit()} type="submit" className="col-12 button yellowBG mb-3">Change Password</button>
                </div>
            );
        }
    }

    onChangePasswordSubmit = async () => {
        if (this.state.password === '') {
            await this.setState({ passwordError: true })
            this.props.onAddPostMessage({ message: ['Password is required.'], color: 'redBG' })
        }

        if (this.state.confirmPassword === '') {
            await this.setState({ confirmPasswordError: true })
            this.props.onAddPostMessage({ message: ['Password confirmation is required.'], color: 'redBG' })
        }

        if (this.state.confirmPassword !== this.state.password) {
            await this.setState({ confirmPasswordError: true })
            this.props.onAddPostMessage({ message: ['Password do not match.'], color: 'redBG' })
        }

        if (!this.state.confirmPasswordError && !this.state.passwordError) {
            this.props.changePassword(this.state.email, this.state.password);
        }
    }

    render() {
        console.log(this.state.recaptchaValue)
        return (
            <div className="text-center container">
                <ActionNotifications msgArray={this.props.messages} removeMessageFunc={this.props.onRemovePostMessage} />
                <div className="row g-0 mx-0 px-0 mt-5">
                    {this.showSpinner()}
                    <div className="mb-5">
                        <img alt="NIAR Logo" className="niarLogo" src={NIARLogo} width="375" />
                        <img alt="Atlas Logo" src={atlasLogoWText} width="175" />
                    </div>
                    {this.startRecoverProccessView()}
                    {this.enterSecurityCodeView()}
                    {this.changePasswordView()}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        messages: state.ToastMessagesReducer.messages,
        requestSecurityCodeStatus: state.forgotPasswordReducer.requestSecurityCodeStatus,
        validateSecurityCodeStatus: state.forgotPasswordReducer.validateSecurityCodeStatus,
        changePasswordStatus: state.forgotPasswordReducer.changePasswordStatus,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ onAddPostMessage, onRemovePostMessage, requestSecurityCode, validateSecurityCode, changePassword, onClearAllMessages }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LogInPage);