import 'regenerator-runtime/runtime';
import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import reduxReset from 'redux-reset';
import { SWRConfig } from 'swr'

import routes from '../../routes';
import createReducers from '../../reducer/reducers';
import rootSaga from '../../saga/sagas';
import './styles.css';

const composeEnhancers = typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const App = () => {
    // store is created with the reducers that are in /reducers

    const reducers = createReducers(routerReducer);
    const sagaMiddleware = createSagaMiddleware();
    const history = createBrowserHistory();

    history.listen((loc, act) => {
        const container = window.document.getElementById('mainContainer');

        if (container) container.scrollTop = 0;
    });

    const middlewares = [sagaMiddleware, routerMiddleware(history)];
    const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewares), reduxReset()));

    sagaMiddleware.run(rootSaga);

    // browser history sync with redux store
    const fetcher = (...args) => fetch(...args).then((res) => res.json());

    return (
        <SWRConfig value={{ fetcher }}>
            <Provider store={store}>
                {routes(history)}
            </Provider>
        </SWRConfig>
    );
};

export default App;
