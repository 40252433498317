import { takeLatest, put } from 'redux-saga/effects';

import { apiBaseURL } from '../../ProjectControls';
import { ON_FORGOT_PASSWORD, ON_VALIDATE_SECURITY_CODE, ON_CHANGE_PASSWORD } from './constants';
import {
    onRequestSecurityCodeFailed,
    onRequestSecurityCodeStart,
    onRequestSecurityCodeSuccess,
    onValidateSecurityCodeFailed,
    onValidateSecurityCodeStart,
    onValidateSecurityCodeSuccess,
    onChangePasswordFailed,
    onChangePasswordStart,
    onChangePasswordSuccess
} from './actions';

import { onAddPostMessage } from '../../actions/toastMessagesActions';

function* forgotPasswordFunc(action) {
    const requestURL = `${apiBaseURL}api/Account/SendSecurityCode?userEmail=${action.email}`;
        
    try {
        yield put(onRequestSecurityCodeStart());

        const response = yield fetch(requestURL);
        const jsonData = yield response.json();

        if (response.status === 200 && jsonData.statusCode === 'Success') {
            yield put(onRequestSecurityCodeSuccess());

        } else {
            yield put(onRequestSecurityCodeFailed());
            const color = jsonData.statusCode === 'Success' ? 'greenBG' : 'redBG';
            yield put(onAddPostMessage({ message: jsonData.messeges, color: color }))

        }

    } catch (e) {
        console.log('An error has occured.', e);
        yield put(onRequestSecurityCodeFailed());
    }
}

function* validateSecurityCodeFunc(action) {
    const requestURL = `${apiBaseURL}api/Account/ValidateSecurityCode?email=${action.email}&securityCode=${parseInt(action.code)}`;
        
    try {
        yield put(onValidateSecurityCodeStart());

        const response = yield fetch(requestURL);
        const jsonData = yield response.json();

        if (response.status === 200 && jsonData.statusCode === 'Success') {
            yield put(onValidateSecurityCodeSuccess());

        } else {
            yield put(onValidateSecurityCodeFailed());

            const color = jsonData.statusCode === 'Success' ? 'greenBG' : 'redBG';
            yield put(onAddPostMessage({ message: jsonData.messeges, color: color }))

        }

    } catch (e) {
        console.log('An error has occured.', e);
        yield put(onValidateSecurityCodeFailed());
    }
}

function* changePasswordFunc(action) {
    const requestURL = `${apiBaseURL}api/Account/ForgotPassword`,
        fetchBody = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: action.email,
                password: action.password
            })
        };
        
    try {
        yield put(onChangePasswordStart());

        const response = yield fetch(requestURL, fetchBody);
        const jsonData = yield response.json();

        if (response.status === 200 && jsonData.statusCode === 'Success') {
            yield put(onChangePasswordSuccess());

        } else {
            yield put(onChangePasswordFailed());
        }

        const color = jsonData.statusCode === 'Success' ? 'greenBG' : 'redBG';
        yield put(onAddPostMessage({ message: jsonData.messeges, color: color }))

    } catch (e) {
        console.log('An error has occured.', e);
        yield put(onChangePasswordFailed());
    }
}

export function* ForgotPasswordSaga() {
    yield takeLatest(ON_FORGOT_PASSWORD, forgotPasswordFunc);
    yield takeLatest(ON_VALIDATE_SECURITY_CODE, validateSecurityCodeFunc);
    yield takeLatest(ON_CHANGE_PASSWORD, changePasswordFunc);
}

export default ForgotPasswordSaga;
