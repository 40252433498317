import {
	GET_PROGRAM_MANAGER,
	ON_PROGRAM_MANAGER_START,
	ON_PROGRAM_MANAGER_FAILED,
	ON_PROGRAM_MANAGER_FINISHED
} from '../constants/programManagersConstants';

export const getProgramManager = () => {
    return ({
        type: GET_PROGRAM_MANAGER,
    });
}

export const onProgramManagerStart = () => {
    return ({
        type: ON_PROGRAM_MANAGER_START,
    });
}

export const onProgramManagerFailed = () => {
    return ({
        type: ON_PROGRAM_MANAGER_FAILED,
    });
}

export const onProgramManagerFinished = (data) => {
    return ({
        type: ON_PROGRAM_MANAGER_FINISHED,
        data
    });
}