import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActionNotifications from '../../component/ActionNotifications';
import Input from '../../component/Input';
import { getUsersName, getUsersEmail } from '../../Helpers'
import atlasLogoWText from '../../Assets/images/AtlasLogoWText.jpg';
import NIARLogo from '../../Assets/images/NIARLogo.png';
import { changePassword } from './actions';
import { onAddPostMessage, onRemovePostMessage } from '../../actions/toastMessagesActions';

class FirstTimeUserPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            oldPassword: '',
            newPasswordError: false,
            oldPasswordError: false,
            email: getUsersEmail(),
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.changePasswordStatus !== this.props.changePasswordStatus) {
            if (this.props.changePasswordStatus === 'Changed') {
                const data = JSON.parse(sessionStorage.getItem("user"))
                data.isNewUser = false;
                await sessionStorage.setItem("user", JSON.stringify(data));

                this.props.history.push('/')
            }
        }
    }

    onLoginInInputChange = async (e) => {
        const inputName = e.target.id;
        const inputNameError = `${e.target.id}Error`;
        const value = e.target.value;

        await this.setState({ [inputName]: value });

        if (inputName !== '') {
            await this.setState({ [inputNameError]: false })
        }
    }

    onChangepasswordclick = async () => {
        if (this.state.oldPassword === '') {
            await this.setState({ oldPasswordError: true })
            this.props.onAddPostMessage({ message: ['Current Password is required.'], color: 'redBG' })
        }

        if (this.state.newPassword === '') {
            await this.setState({ newPasswordError: true })
            this.props.onAddPostMessage({ message: ['New Password is required.'], color: 'redBG' })
        }

        if (!this.state.newPasswordError && !this.state.oldPasswordError) {
            this.props.changePassword(this.props.history, this.state.email, this.state.newPassword, this.state.oldPassword)
        }
    }

    render() {
        return (
            <div className="text-center container">
                <div className="row mx-0 px-0 mt-5 g-0">
                    <ActionNotifications msgArray={this.props.messages} removeMessageFunc={this.props.onRemovePostMessage} />
                    <div className="mb-5">
                        <img alt="NIAR Logo" className="niarLogo" src={NIARLogo} width="375" />
                        <img alt="Atlas Logo" src={atlasLogoWText} width="175" />
                    </div>
                    <div className="mb-4">
                        <h2>Welcome, {getUsersName()}.</h2>
                        <h4>Please change your password below.</h4>
                    </div>
                    <Input
                        isError={this.state.oldPasswordError}
                        id="oldPassword"
                        onChange={this.onLoginInInputChange}
                        value={this.state.oldPassword}
                        labelTitle="Current Password"
                        type="password"
                    />
                    <Input
                        isError={this.state.newPasswordError}
                        id="newPassword"
                        onChange={this.onLoginInInputChange}
                        value={this.state.newPassword}
                        labelTitle="New Password"
                        type="password"
                    />
                    <button type="submit" className="button yellowBG mb-3" onClick={this.onChangepasswordclick}>Change Password</button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        messages: state.ToastMessagesReducer.messages,
        changePasswordStatus: state.ChangePasswordReducer.changePasswordStatus
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ onAddPostMessage, onRemovePostMessage, changePassword }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstTimeUserPage);