import {
    ON_FETCH_PROJECT_DATA_START,
    ON_FETCH_PROJECT_DATA_SUCCESS,
    ON_FETCH_PROJECT_DATA_FAILED,
    REMOVE_FILTERED_PROJECT_DATA,
    FILTER_PROJECT_DATA
} from '../constants/projectEventsConstants';

const initialState = {
    projectData: [],
    projectDataStatus: '',
    filteredProjectData: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ON_FETCH_PROJECT_DATA_START:
            return { ...state, projectDataStatus: 'Started' };

        case ON_FETCH_PROJECT_DATA_SUCCESS:
            let filteredProjectDataArray = [];

            if (state.filteredProjectData.some(lab => lab.labName === action.data[0].labName)) {
                // check if the object has updated

                // find the common labs between the array
                //console.log('test', state.filteredProjectData, action.data, state.filteredProjectData.filter(lab => lab.labName === action.data.map(dataLab => dataLab.labName)))
                let array = [];

                for (let i = 0; i < state.filteredProjectData.length; i++) {
                    for (let j = 0; j < action.data.length; j++) {   
                        if (state.filteredProjectData[i].labName === action.data[j].labName) {
                            array.push(state.filteredProjectData[i].labName)
                        }
                    }
                }
                //see if the object is the same
                if (array.length > 0) {
                    console.log('test', array)

                    //replace all the obj with same lab obj from action.data

                    let filteredArr = [];

                    array.filter(labNames => {
                        action.data.map(lab => {
                            if (lab.labName === labNames) {
                                filteredArr.push(lab);
                            }
                        })
                    });

                    filteredProjectDataArray = filteredArr;
                    // if the same object from action.data move on
                    //JSON.stringify(a) === JSON.stringify(b);
                    // else replace the old object with the updated object
                } else {
                    filteredProjectDataArray = state.filteredProjectData;
                }
            } else {
                filteredProjectDataArray = [...state.filteredProjectData]
            }

            return { ...state, projectDataStatus: 'Finished', projectData: action.data, filteredProjectData: filteredProjectDataArray };

        case ON_FETCH_PROJECT_DATA_FAILED:
            return { ...state, projectDataStatus: 'Failed' };

        case REMOVE_FILTERED_PROJECT_DATA:
            return { ...state, filteredProjectData: state.filteredProjectData.filter(lab => lab.labName !== action.data) };

        case FILTER_PROJECT_DATA:
            return { ...state, filteredProjectData: [...state.filteredProjectData, action.data] };
            //return { ...state, filteredProjectData: action.data };

        default:
            return state;
    }
};

export default reducer;